import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useSubscription } from '@hooks/useSubscription';
import FreeView from '@pages/Settings/components/BillingTab/components/FreeView';
import MonthlyView from '@pages/Settings/components/BillingTab/components/MonthlyView';
import YearlyView from '@pages/Settings/components/BillingTab/components/YearlyView';

import { Box, Typography } from '@mui/material';

const BillingTab: FC = () => {
  const { t } = useTranslation();
  const { isTrial, subscriptionType, trialDaysLeft, daysLeft, portalUrl, cardNumber } = useSubscription();
  const navigate = useNavigate();

  const handleRedirectToSubscribe = () => navigate(`/${ROUTING.SUBSCRIBE}`);
  const handleRedirectToPortal = () => portalUrl && !Array.isArray(portalUrl) && window.open(portalUrl);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      pt={{
        xs: 5,
        md: 7.5,
      }}
      px={{
        xs: 2,
        sm: 2.5,
        md: 0,
        lg: 0,
      }}
      maxWidth={{
        xs: '100%',
        sm: '560px',
        md: '550px',
        lg: '505px',
      }}
      pb={5}
    >
      <Typography fontFamily="WFVisualSans" fontSize="24px" pb="31px">
        {t('settings.billingTabHeader')}
      </Typography>
      {isTrial && <FreeView daysLeft={trialDaysLeft} handleRedirect={handleRedirectToSubscribe} />}
      {!isTrial && subscriptionType && subscriptionType === 'annual' && (
        <YearlyView daysLeft={daysLeft} handleCancel={handleRedirectToPortal} cardNumber={cardNumber} />
      )}
      {!isTrial && subscriptionType && subscriptionType === 'monthly' && (
        <MonthlyView
          handleRedirect={handleRedirectToSubscribe}
          daysLeft={daysLeft}
          handleCancel={handleRedirectToPortal}
          cardNumber={cardNumber}
        />
      )}
    </Box>
  );
};

export default memo(BillingTab);
