import { api } from './api';
import Tag from './tag';

const provideTagsArray = <T extends { id: number }>(
  result: T[] | { results: T[] | undefined } | undefined,
  tag: Tag,
) => {
  const results = Array.isArray(result) ? result : result?.results;

  if (results?.length) {
    return results.map(({ id }) => ({ type: tag, id }));
  }

  return [{ type: tag }];
};

api.enhanceEndpoints({
  endpoints: {
    authUserRetrieve: {
      providesTags: () => {
        return [{ type: Tag.USER }];
      },
    },
    apiInvoicesList: {
      providesTags: result =>
        result?.results?.map(({ id }) => {
          return { type: Tag.INVOICE_LIST, id };
        }) || [{ type: Tag.INVOICE_LIST }],
    },
    apiInvoicesRetrieve: {
      providesTags: result => [{ type: Tag.INVOICE, id: result?.id }],
    },
    apiInvoicesUpdatePartialUpdate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE, id: args.id }, { type: Tag.INVOICE_CHANGE }, { type: Tag.INVOICE_LIST }];
      },
    },
    apiInvoicesChangesList: {
      providesTags: result => [{ type: Tag.INVOICE_CHANGE }],
    },
    apiInvoicesRevertCreate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE, id: args.id }, { type: Tag.INVOICE_CHANGE }];
      },
    },
    apiInvoicesDestroy: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_LIST, id: args.id }];
      },
    },
    apiInvoicesNotesList: {
      providesTags: result =>
        result?.map(({ id }) => {
          return { type: Tag.INVOICE_NOTES_LIST, id };
        }) || [{ type: Tag.INVOICE_NOTES_LIST }],
    },
    apiInvoicesNotesCreate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_NOTES_LIST }];
      },
    },
    apiInvoicesNotesPartialUpdate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_NOTES_LIST, id: args.id }];
      },
    },
    apiInvoicesNotesDestroy: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_NOTES_LIST, id: args.id }];
      },
    },
    apiInvoicesEventsList: {
      providesTags: result =>
        result?.map(({ id }) => {
          return { type: Tag.INVOICE_EVENTS_LIST, id };
        }) || [{ type: Tag.INVOICE_EVENTS_LIST }],
    },
    apiInvoicesEventsCreate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_EVENTS_LIST }, { type: Tag.INVOICE }, { type: Tag.INVOICE_LIST }];
      },
    },
    apiInvoicesEventsPartialUpdate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_EVENTS_LIST, id: args.id }, { type: Tag.INVOICE }, { type: Tag.INVOICE_LIST }];
      },
    },
    apiInvoicesEventsDestroy: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.INVOICE_EVENTS_LIST, id: args.id }, { type: Tag.INVOICE }, { type: Tag.INVOICE_LIST }];
      },
    },
    authUserPartialUpdate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.USER }];
      },
    },
    authUserParticipantsList: {
      providesTags: result =>
        result?.map(({ id }) => {
          return { type: Tag.PARTICIPANTS_LIST, id };
        }) || [{ type: Tag.PARTICIPANTS_LIST }],
    },
    authUserParticipantsPartialUpdate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.PARTICIPANTS_LIST, id: args.id }];
      },
    },
    authUserParticipantsCreate: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.PARTICIPANTS_LIST }];
      },
    },
  },
});
