import { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const MuiDialog: {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
} = {
  styleOverrides: {
    root: {
      zIndex: 3000,
    },
  },
};
