import { FC, memo, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PaginatedInvoiceListRead } from '@api/api';
import NiceModal from '@ebay/nice-modal-react';
import { ICONS } from '@pages/Invoices/enums';
import { useGroupedByDateInvoices } from '@pages/Invoices/hooks/useGroupedByDateInvoices';
import { QuickInfoModalId } from '@pages/Invoices/modals/QuickInfoModal';
import { StyledChipDayOfService, StyledMobileInvoiceRow } from '@pages/Invoices/styled';
import { ExtendedActiveParticipant } from '@pages/Invoices/types';
import { getCurrentDayOfService, getFinalDayOfService } from '@pages/Invoices/utils';
import { getDay, getDayOfMonth, getMonthFull } from '@utils/formatTime';

import CircleIcon from '@mui/icons-material/Circle';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';

interface InvoiceListMobileProps {
  activeParticipants: ExtendedActiveParticipant[] | [];
  invoicesData: PaginatedInvoiceListRead;
  selectedMonth: string;
  pickedDate: string | null;
}

interface SelectRowProps {
  idx: string;
  invoiceId: number;
  date: string;
}

const InvoiceListMobile: FC<InvoiceListMobileProps> = ({
  invoicesData,
  activeParticipants,
  selectedMonth,
  pickedDate,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
  const { t } = useTranslation();
  const invoiceRowRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { listToRender, groupedByDate } = useGroupedByDateInvoices(invoicesData);

  const handleSelectRow = async (props: SelectRowProps) => {
    const { idx, date, invoiceId } = props;
    setSelectedInvoice(idx);
    NiceModal.show(QuickInfoModalId, {
      invoice: groupedByDate[date]?.find(inv => inv.id === invoiceId),
    });
  };

  useEffect(() => {
    if (pickedDate) {
      const day = new Date(pickedDate).getDate().toString().padStart(2, '0');
      const dayElements = document.querySelectorAll('div.MuiBox-root p.MuiTypography-root:first-of-type');

      const matchingElement = Array.from(dayElements).find(el => el.textContent?.trim() === day)?.parentElement;

      if (matchingElement) {
        matchingElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }

    return () => {
      if (selectedMonth !== pickedDate) {
        invoiceRowRefs.current = [];
      }
    };
  }, [pickedDate, listToRender, selectedMonth]);

  return (
    <Box mt={3} display="flex" flexDirection="column" gap={4}>
      {Object.entries(groupedByDate).map(([date, invoices], index) => (
        <Box key={date}>
          {/* Render the date */}
          <Box display="flex" gap="9px" mb={1}>
            <Typography fontWeight={600} fontSize={18} color="textSecondary">
              {getDayOfMonth(date)}
            </Typography>
            <Typography fontWeight={400} fontSize={18} color="textSecondary">
              {getDay(date, 'd')}
            </Typography>
          </Box>

          {/* Render invoices for the date */}
          {invoices.map((invoice, idx) => {
            const invoiceRole = activeParticipants.find(
              ap => `${ap.first_name} ${ap.last_name}` === invoice?.participant,
            )?.role;

            const showParticipants = !!(
              activeParticipants.length > 1 &&
              invoice.participant &&
              activeParticipants.some(
                participant => `${participant.first_name} ${participant.last_name}` === invoice.participant,
              )
            );
            return (
              <StyledMobileInvoiceRow
                key={invoice.invoice_id}
                ref={el => (invoiceRowRefs.current[idx] = el as HTMLDivElement | null)}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                isLast={invoiceRole === ICONS.CIRCLE && showParticipants}
                isSelected={`${index} ${idx}` === selectedInvoice}
                onClick={() =>
                  handleSelectRow({
                    idx: `${index} ${idx}`,
                    date,
                    invoiceId: invoice.id,
                  })
                }
                my={idx === invoices.length - 1 ? 0 : 1}
              >
                <Typography>{invoice.description || t('common.noDescription')}</Typography>
                <Box display="flex" alignItems="center" gap="5px">
                  {invoice.support_date && invoice.service_start_date && invoice.service_end_date && (
                    <StyledChipDayOfService
                      label={`Day ${getCurrentDayOfService(invoice.support_date, invoice.service_start_date)} of ${getFinalDayOfService(invoice.service_start_date, invoice.service_end_date)}`}
                      color="secondary"
                      size="small"
                      variant="filled"
                    />
                  )}
                  {showParticipants &&
                    (invoiceRole === ICONS.CIRCLE ? <CircleIcon fontSize="small" /> : <StarIcon fontSize="small" />)}
                </Box>
              </StyledMobileInvoiceRow>
            );
          })}
        </Box>
      ))}

      {/* NO DATA TO SHOW */}
      {listToRender.length < 1 && (
        <Box mt="170px" display="flex" width="100%" alignItems="center" justifyContent="center">
          <Typography fontSize={13} fontWeight={400} color="textSecondary">
            <Trans i18nKey={t('dashboard.noRecordsInMonth')} values={{ month: getMonthFull(selectedMonth) }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default memo(InvoiceListMobile);
