import { FC, memo, ReactNode } from 'react';
import { StyledPillBox, StyledPillTypography } from '@pages/Settings/components/BillingTab/components/styled';

interface SubscriptionPillProps {
  icon: ReactNode;
  locale: string;
}

const SubscriptionPill: FC<SubscriptionPillProps> = ({ locale, icon }) => (
  <StyledPillBox width="fit-content">
    <StyledPillTypography>{locale}</StyledPillTypography>
    {icon}
  </StyledPillBox>
);

export default memo(SubscriptionPill);
