import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS, MAX_FORMATTED_ABN_SYMBOLS, MIN_CHARACTERS } from '@constants/common';
import { TaskView } from '@pages/Tasks/enums';
import { InvoiceEdit } from '@pages/Tasks/types';
import { format, parseISO } from 'date-fns';

type TasksTab = 'claim' | 'pay';

export const compareVendorNames = (a: InvoiceRead, b: InvoiceRead) => {
  if (!a.vendor_name && !b.vendor_name) return 0;
  if (!a.vendor_name) return -1;
  if (!b.vendor_name) return 1;

  return a.vendor_name.toLowerCase().localeCompare(b.vendor_name.toLowerCase());
};

export const getEffectiveDate = (invoice: InvoiceRead) => {
  if (invoice.service_exact_date) {
    return new Date(invoice.service_exact_date);
  }
  if (invoice.service_start_date) {
    return new Date(invoice.service_start_date);
  }
  return null;
};

export const compareDates = (a: InvoiceRead, b: InvoiceRead) => {
  const dateA = getEffectiveDate(a);
  const dateB = getEffectiveDate(b);

  // If both dates are null, they're equal
  if (!dateA && !dateB) return 0;
  // Null dates come first
  if (!dateA) return -1;
  if (!dateB) return 1;

  // DESC order
  return dateB.getTime() - dateA.getTime();
};

export const getTranslationKey = (amount: number, tab: TasksTab) => {
  // if (amount === 0) return `tasks.${tab}.subtitle_zero`;
  if (amount === 1) return `tasks.${tab}.subtitle_single`;
  return `tasks.${tab}.subtitle_many`;
};

export const getTotalSelectedCost = (ids: number[], invoices: InvoiceRead[]) => {
  if (ids.length < 1) return '0.00';
  const totalNumber = invoices
    .filter(inv => ids.includes(inv.id))
    .reduce((acc, curr) => {
      if (!curr.total_amount) return acc;
      return acc + parseFloat(curr.total_amount);
    }, 0);
  return totalNumber.toFixed(2);
};

export const findInvoiceIssues = (invoice: InvoiceRead) => {
  if (!invoice) return false;
  let hasIssue = false;

  // Description validation
  if (
    !invoice?.description ||
    invoice?.description?.length < MIN_CHARACTERS ||
    invoice?.description?.length > MAX_DESCRIPTION_CHARACTERS ||
    !/[A-Za-z0-9]/.test(invoice.description)
  ) {
    hasIssue = true;
  }

  // Total amount validation
  if (
    !invoice?.total_amount ||
    invoice?.total_amount === '0.00' ||
    invoice?.total_amount === '0' ||
    invoice?.total_amount === '0,00' ||
    invoice?.total_amount === ''
  ) {
    hasIssue = true;
  }

  // Service date validation
  if (!invoice?.service_start_date && !invoice?.service_end_date && !invoice?.service_exact_date) {
    hasIssue = true;
  }

  // Vendor name validation
  if (
    !invoice?.vendor_name ||
    invoice?.vendor_name?.length < MIN_CHARACTERS ||
    invoice?.vendor_name?.length > MAX_DESCRIPTION_CHARACTERS ||
    !/[A-Za-z0-9]/.test(invoice.vendor_name)
  ) {
    hasIssue = true;
  }

  // ABN validation
  if (!invoice?.reason?.id) {
    if (!invoice?.abn || invoice?.abn?.length < MAX_FORMATTED_ABN_SYMBOLS) {
      hasIssue = true;
    }
  }

  // Category validation
  if (!invoice?.category?.id) {
    hasIssue = true;
  }

  // Participant validation
  if (!invoice?.participant) {
    hasIssue = true;
  }

  return hasIssue;
};

export const getDateKey = (invoice: InvoiceRead): string => {
  const date = invoice?.service_exact_date || invoice?.service_start_date;
  if (!date) return 'null';

  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'yyyy-MM');
  } catch {
    return 'null';
  }
};

export const groupInvoicesByDate = (data: InvoiceRead[]) => {
  const groups: { [key: string]: InvoiceRead[] } = {};

  data.forEach(inv => {
    const key = getDateKey(inv);

    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(inv);
  });

  // Sort keys in descending order, keeping 'null' at the start
  const sortedKeys = Object.keys(groups).sort((a, b) => {
    if (a === 'null') return -1;
    if (b === 'null') return 1;
    return b.localeCompare(a);
  });

  const sortedGroups: { [key: string]: InvoiceRead[] } = {};
  sortedKeys.forEach(key => {
    sortedGroups[key] = groups[key];
  });

  return sortedGroups;
};

export const getSelectedInvoices = (ids: number[], invoices: InvoiceRead[]) =>
  invoices.filter(inv => ids.includes(inv.id));

export const transformInvoices = (ids: number[], invoices: InvoiceRead[], view: TaskView) => {
  const arr = getSelectedInvoices(ids, invoices).map(inv => ({
    ...(view === TaskView.CLAIM && { claimNumber: '' }),
    ...(view === TaskView.CLAIM && { claimDate: null }),
    ...(view === TaskView.PAY && { payNumber: '' }),
    ...(view === TaskView.PAY && { payDate: null }),
    id: inv.id,
  }));
  return {
    data: arr,
  };
};

export const findEditInvoice = (invoice: InvoiceEdit, invoices: InvoiceRead[]) =>
  invoices.find(inv => invoice.id === inv.id);
