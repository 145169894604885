import { FC, memo } from 'react';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePathname } from '@hooks/usePathname';
import RouterLink from '@routes/components/RouterLink';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { alpha } from '@mui/material/styles';

interface NavItemProps {
  item: {
    path: string;
    title: string;
  };
}

const NavItem: FC<NavItemProps> = ({ item }) => {
  const pathname = usePathname();
  const { downSm } = useBreakpoints();

  const isRootPath = item.path === ROUTING.ROOT;
  const active = isRootPath ? pathname === ROUTING.ROOT : pathname.startsWith(item.path);

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      role="menuitem"
      sx={{
        minHeight: 38,
        color: '#495057',
        textTransform: 'capitalize',
        fontWeight: 500,
        fontSize: 16,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        ...(active && {
          color: theme => theme.colors.secondary.contrast,
          fontWeight: 600,
          bgcolor: '#F4ECFF',
          borderBottom: theme => `2px solid ${theme.colors.primary.main}`,
          '&:hover': {
            bgcolor: alpha('#F4ECFF', 0.5),
          },
        }),
        justifyContent: 'center',
        maxWidth: downSm ? 'auto' : '337px',
      }}
    >
      <Box component="span" sx={{ textWrap: 'nowrap' }} aria-label={`link to ${item.title}`}>
        {item.title}
      </Box>
    </ListItemButton>
  );
};

export default memo(NavItem);
