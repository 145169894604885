import { Breakpoint } from '@mui/material/styles';

export const API_ERROR_MSG_PATH = 'data.error.fields.non_field_errors';
export const COUNTDOWN_DATE = new Date(0);
export const NOT_AVAILABLE = 'Not available';
export const DEFAULT_PAGE_LIMIT = 10;
export const MAX_DAYS_IN_MONTH = 31;
export const MONTHS_IN_YEAR = 12;
export const INVOICE_ID = 'invoiceId';
export const MAX_UPLOAD_FILES = 10;
export const PAGE_HEADER_RAW_HEIGHT = 250;
export const MAX_FORMATTED_ABN_SYMBOLS = 11;
export const MAX_DESCRIPTION_CHARACTERS = 40;
export const UP = 'up';
export const DOWN = 'down';
export const APP_BAR_HEIGHT = 78;
export const MAX_ID_LENGTH = 30;
export const MIN_CHARACTERS = 3;
export const MAX_NOTE_CHARACTERS = 500;
export const DAYS_PER_WEEK = 7;

export const BREAKPOINTS: Record<'XS' | 'SM' | 'MD' | 'LG' | 'XL', Breakpoint> = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};
