export enum TaskView {
  CLAIM = 'CLAIM',
  PAY = 'PAY',
}
export enum DocumentView {
  DETAILS = 'DETAILS',
  EDITOR = 'EDITOR',
}

export enum SortBy {
  NONE = 'NONE',
  DESC = 'DESC',
  DATE = 'DATE',
}
