import { FC, memo, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { usePartialUpdate } from '@hooks/api/usePartialUpdate';
import {
  StyledAccordionInnerBox,
  StyledAccordionSummary,
  StyledIssueAccordion,
  StyledIssueAccordionDetails,
} from '@pages/InvoiceDetails/components/DetailsTab/components/IssueBlock/styled';
import MissingDetailsForm from '@pages/InvoiceDetails/components/DetailsTab/components/MissingDetailsForm';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { DetailsSchema } from '@pages/InvoiceDetails/schema/types';
import { formatDate } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';

const IssueBlock: FC<{ count: number }> = ({ count }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [expanded, setExpanded] = useState(false);
  const [shouldShowDescription, setShouldShowDescription] = useState<boolean | null>(null);

  const { detailsForm, invoiceId, invoiceData, handleResetForm, invoiceForm } = useInvoiceDetailsNewContext();
  const { isLoadingPartialUpdate, partialUpdateError, updateInvoice } = usePartialUpdate(invoiceId);

  const handleToggle = () => setExpanded(!expanded);

  const handleSubmitMissingDetails: SubmitHandler<DetailsSchema> = async ({
    missing_category,
    missing_reason,
    missing_description,
    missing_vendor_name,
    missing_total_amount,
    missing_participant,
    missing_service_exact_date,
  }) => {
    try {
      const payload = {
        ...(missing_category &&
          missing_category?.id !== invoiceData?.category?.id && { category_id: missing_category.id }),
        ...(missing_reason && missing_reason?.id !== invoiceData?.reason?.id && { reason_id: missing_reason.id }),
        ...(missing_description &&
          missing_description !== invoiceData?.description && { description: missing_description }),
        ...(missing_vendor_name &&
          missing_vendor_name !== invoiceData?.vendor_name && { vendor_name: missing_vendor_name }),
        ...(missing_total_amount &&
          missing_total_amount !== invoiceData?.total_amount && {
            total_amount: missing_total_amount.replace(/,/g, ''),
          }),
        ...(missing_participant && { participant: missing_participant }),
        ...(missing_service_exact_date && { service_exact_date: formatDate(new Date(missing_service_exact_date)) }),
      };

      await updateInvoice(payload);
      if (partialUpdateError) {
        return snackbar.enqueueSnackbar(getErrorMessage(partialUpdateError, API_ERROR_MSG_PATH), { variant: ERROR });
      }
      handleToggle();
      setShouldShowDescription(null);
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH));
    }
  };

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
    invoiceForm.trigger();
  }, [handleResetForm, invoiceData, invoiceForm]);

  return (
    <Box mb={2}>
      <StyledIssueAccordion expanded={expanded} onChange={handleToggle}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontFamily="WFVisualSans" color="#5F2120">
              {t('dashboard.invoiceDetails.incompleteDetails')}
            </Typography>
            <StyledAccordionInnerBox>
              <Typography fontSize={12} fontWeight={500}>
                {count}
              </Typography>
            </StyledAccordionInnerBox>
          </Box>
        </StyledAccordionSummary>
        <StyledIssueAccordionDetails>
          <FormProvider {...detailsForm}>
            <form
              onSubmit={detailsForm.handleSubmit(handleSubmitMissingDetails, (errors, e) =>
                handleSubmitMissingDetails(detailsForm.getValues(), e),
              )}
            >
              <MissingDetailsForm
                loading={isLoadingPartialUpdate}
                data={invoiceData}
                shouldShowDescription={shouldShowDescription}
                setShouldShowDescription={setShouldShowDescription}
              />
            </form>
          </FormProvider>
        </StyledIssueAccordionDetails>
      </StyledIssueAccordion>
    </Box>
  );
};

export default memo(IssueBlock);
