import { Box, BoxProps, Dialog, DialogProps, IconButtonProps, Typography, TypographyProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const mapSizes = {
  xs: {
    '.MuiPaper-root': {
      maxWidth: '358px',
      width: '100%',
    },
  },
  small: {
    '.MuiPaper-root': {
      maxWidth: '390px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1080px',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'xs' | 'small' | 'medium' | 'xl' }>(({ size }) => ({
  zIndex: 2000,
  ...(size && mapSizes[size]),
}));

export const StyledTypographyContrast = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));

export const StyledTypographyPrimaryDark = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.dark,
}));

export const StyledTypographyPrimaryMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.main,
}));

export const StyledTypographyPrimaryContrast = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.contrast,
}));

export const StyledWrapTypography = styled(Typography)<TypographyProps>(() => ({
  textWrap: 'wrap',
  wordBreak: 'break-word',
}));

export const StyledModalTitleBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: theme.colors.background.detailsBackground,
  padding: '15px 20px',
  color: '#000000DE',
}));

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: '2px',
});
