import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BillingDetailsRow from '@pages/Settings/components/BillingTab/components/BillingDetailsRow';
import { StyledSwitchPlanBox } from '@pages/Settings/components/BillingTab/components/styled';
import SubscriptionPill from '@pages/Settings/components/BillingTab/components/SubscriptionPill';
import { MONTHLY_PRICE, YEARLY_PRICE } from '@pages/Settings/constants';
import { useBillingInfo } from '@pages/Settings/hooks/useBillingInfo';
import { AUD, YEARLY_SAVE_TOTAL } from '@pages/SubscribePage/constants';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, Divider, Typography } from '@mui/material';

import { StyledTypographyContrast, StyledTypographyPrimaryDark } from '@/shared/styles';

const PERIOD = 'dashboard.subscribePage.plans.annually.period';

const MonthlyView: FC<{
  handleRedirect: () => void;
  daysLeft: number;
  handleCancel: () => void;
  cardNumber: string;
}> = ({ handleRedirect, daysLeft, handleCancel, cardNumber }) => {
  const { t } = useTranslation();
  const details = useBillingInfo({
    period: PERIOD,
    price: MONTHLY_PRICE,
    cardNumber, // todo redo when api will be introduced
    daysLeft,
  });

  return (
    <Box width="100%">
      <SubscriptionPill icon={<TaskAltIcon />} locale={t('settings.activeMonthlySubscription')} />

      <StyledSwitchPlanBox mt="27px">
        <Box p="25px 30px">
          <StyledTypographyContrast fontFamily="WFVisualSans" fontSize={20}>
            {t('settings.switchAndSave')}
          </StyledTypographyContrast>
          <StyledTypographyContrast fontSize={18} fontWeight={400} mt={3}>
            {`$${YEARLY_PRICE.toFixed(2)} ${AUD}, ${t(PERIOD)}`}
          </StyledTypographyContrast>

          <StyledTypographyContrast fontSize={14} fontWeight={400} mt={2} mb={0.5} maxWidth={360}>
            <Trans
              i18nKey={t('dashboard.subscribePage.plans.annually.text')}
              components={{
                bold: <StyledTypographyPrimaryDark fontWeight={700} variant="subtitle1" component="span" />,
              }}
              values={{
                total: YEARLY_SAVE_TOTAL,
              }}
            />
          </StyledTypographyContrast>
        </Box>
      </StyledSwitchPlanBox>

      <Button
        disableRipple
        fullWidth
        onClick={handleRedirect}
        color="primary"
        style={{
          height: 42,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
      >
        {t('settings.switchToYearly')}
      </Button>

      <Box mt="47px" mb={7} display="flex" flexDirection="column" gap={1.5}>
        {details.map(({ icon, detailsTitle, detailsText }, index) => (
          <Box display="flex" key={detailsTitle} flexDirection="column" gap={1}>
            <BillingDetailsRow icon={icon} detailsTitle={detailsTitle} detailsText={detailsText} />
            {details.length - 1 !== index && <Divider />}
          </Box>
        ))}
      </Box>

      <Box width="100%" display="flex" flexDirection="column" gap={5}>
        <Button
          disableRipple
          fullWidth
          onClick={handleCancel}
          color="primary"
          style={{
            height: 42,
            boxShadow: 'none',
          }}
          variant="outlined"
        >
          <Typography>{t('settings.manageBilling')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(MonthlyView);
