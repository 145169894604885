import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDifferenceDate } from '@pages/Settings/utils/getBillingDates';
import { AUD } from '@pages/SubscribePage/constants';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentIcon from '@mui/icons-material/Payment';
import ReplayIcon from '@mui/icons-material/Replay';

interface BillingInfoConfig {
  period: string;
  cardNumber: string;
  daysLeft: number;
  price: number;
}

export const useBillingInfo = (config: BillingInfoConfig) => {
  const { t } = useTranslation();
  const { period, daysLeft, cardNumber, price } = config;

  return useMemo(
    () => [
      {
        icon: <ReplayIcon color="action" />,
        detailsTitle: 'settings.billingFreq',
        detailsText: period,
      },
      {
        icon: <CalendarTodayIcon color="action" />,
        detailsTitle: 'settings.nextPaymentDate',
        detailsText: getDifferenceDate(daysLeft),
      },
      {
        icon: <PaidIcon color="action" />,
        detailsTitle: 'settings.nextPaymentAmount',
        detailsText: `$${price.toFixed(2)} ${AUD}`,
      },
      {
        icon: <PaymentIcon color="action" />,
        detailsTitle: 'settings.paymentCard',
        detailsText: t('settings.paymentCardEnding', { digits: cardNumber }),
      },
    ],
    [cardNumber, daysLeft, period, price, t],
  );
};
