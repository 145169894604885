import { useMemo } from 'react';
import { CustomUserDetailsRead, InvoiceChangeLogRead, InvoiceRead } from '@api/api';
import { BREAKPOINTS, UP } from '@constants/common';
import { useResponsive } from '@hooks/useResponsive';
import {
  ABN_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  ORIGINAL_FILE_NAME,
  PARTICIPANT_FIELD,
  REASON_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_EXACT_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  TOTAL_AMOUNT_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { getDetailsIconEnum } from '@pages/InvoiceDetails/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

export const useInvoiceDetailsConfig = (
  userData?: CustomUserDetailsRead,
  invoiceData?: InvoiceRead,
  changesData?: InvoiceChangeLogRead[],
) => {
  const serviceExactDay =
    invoiceData?.service_exact_date && convertIsoToReadable(invoiceData.service_exact_date, { fullYear: true });
  const serviceStartDay =
    !serviceExactDay &&
    invoiceData?.service_start_date &&
    convertIsoToReadable(invoiceData.service_start_date, { fullYear: true });
  const serviceEndDay =
    !serviceExactDay &&
    invoiceData?.service_end_date &&
    convertIsoToReadable(invoiceData.service_end_date, { fullYear: true });

  const upSm = useResponsive(UP, BREAKPOINTS.SM);

  const config = useMemo(() => {
    const details = [
      {
        id: 1,
        title: 'dashboard.invoiceDetails.participant',
        value: invoiceData?.participant || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(PARTICIPANT_FIELD, invoiceData?.participant, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        id: 2,
        title: 'dashboard.invoiceDetails.amountDescription',
        value: `${invoiceData?.currency_symbol}${formatCurrency(invoiceData?.total_amount)}`,
        iconStatus: getDetailsIconEnum(TOTAL_AMOUNT_FIELD, invoiceData?.total_amount, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        id: 3,
        title: 'dashboard.invoiceDetails.supportDateDescription',
        value: serviceExactDay || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(SERVICE_EXACT_DATE_FIELD, invoiceData?.service_exact_date, changesData),
        renderCondition: !serviceStartDay && !serviceEndDay,
        dimensions: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        id: 4,
        title: 'dashboard.invoiceDetails.supportDateStartDescription',
        value: serviceStartDay,
        iconStatus: getDetailsIconEnum(SERVICE_START_DATE_FIELD, invoiceData?.service_start_date, changesData),
        renderCondition: serviceStartDay,
        dimensions: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        id: 5,
        title: 'dashboard.invoiceDetails.supportDateEndDescription',
        value: serviceEndDay,
        iconStatus: getDetailsIconEnum(SERVICE_END_DATE_FIELD, invoiceData?.service_end_date, changesData),
        renderCondition: serviceEndDay,
        dimensions: {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        id: 6,
        title: 'dashboard.invoiceDetails.supportDescription',
        value: invoiceData?.description || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(DESCRIPTION_FIELD, invoiceData?.description, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      },
      {
        id: 7,
        title: 'dashboard.invoiceDetails.selectSupportCategory',
        value: invoiceData?.category?.name || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(CATEGORY_FIELD, invoiceData?.category?.name, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      },
      {
        id: 8,
        title: 'dashboard.invoiceDetails.providerDescription',
        value: invoiceData?.vendor_name || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(VENDOR_NAME_FIELD, invoiceData?.vendor_name, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      },
      {
        id: 9,
        title: 'dashboard.invoiceDetails.abnDescription',
        value: invoiceData?.abn || 'common.noneFound',
        iconStatus: getDetailsIconEnum(ABN_FIELD, invoiceData?.abn, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 3,
          lg: 3,
        },
        tooltipTitle:
          invoiceData?.abn || invoiceData?.reason ? undefined : 'dashboard.invoiceDetails.missingAbnTooltip',
      },
      {
        id: 10,
        title: 'dashboard.abnSelectReason',
        value: invoiceData?.reason?.name || 'common.actionRequired',
        iconStatus: getDetailsIconEnum(REASON_FIELD, invoiceData?.reason?.name, changesData),
        renderCondition: !invoiceData?.abn && invoiceData?.reason?.id,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      },
      {
        id: 11,
        title: 'dashboard.invoiceDetails.attachmentName',
        value: invoiceData?.original_filename,
        iconStatus: getDetailsIconEnum(ORIGINAL_FILE_NAME, invoiceData?.original_filename, changesData),
        renderCondition: true,
        dimensions: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
        tooltipTitle: invoiceData?.original_filename ? undefined : 'dashboard.invoiceDetails.attachmentMissing',
      },
    ];

    if (upSm) {
      const missingReasonIdx = details.findIndex(item => item.id === 10);
      const attachmentIdx = details.findIndex(item => item.id === 11);

      if (missingReasonIdx !== -1 && attachmentIdx !== -1) {
        [details[missingReasonIdx], details[attachmentIdx]] = [details[attachmentIdx], details[missingReasonIdx]];
      }
    }

    return details;
  }, [userData, invoiceData, changesData, serviceExactDay, serviceStartDay, serviceEndDay, upSm]);

  return { config };
};
