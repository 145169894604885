import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_DOMAIN } from '@constants/auth';
import { MAX_ID_LENGTH } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import EditId from '@pages/CreateID/components/EditId';
import { StyledEditBox, StyledEmailIdTypography, StyledIdBox, StyledPaper } from '@pages/CreateID/components/styled';
import { useCreateIdContext } from '@pages/CreateID/context';
import { StyledBox } from '@pages/styled';
import { SnackbarKey } from 'notistack';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import RegistrationHeader from '@components/RegistrationHeader';

const CreateIDForm: FC<{
  loading: boolean;
  handleCreate: () => Promise<void> | Promise<SnackbarKey | undefined>;
  isLoadingInitialEmailCheck: boolean
}> = ({
        loading,
        handleCreate,
        isLoadingInitialEmailCheck
      }) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors }
  } = useFormContext();
  const { toggleEdit, editId } = useCreateIdContext();
  const { downSm } = useBreakpoints();
  const emailId = watch('emailId');

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" aria-label="Create CapsureID form">
      <RegistrationHeader />
      <StyledPaper
        elevation={6}
        sx={{
          px: {
            xs: '25px',
            sm: '40px'
          },
          py: '30px',
          maxWidth: {
            sm: '560px',
            md: '550px',
            lg: '505px'
          }
        }}
      >
        <StyledBox>
          <Typography fontSize={20} fontFamily="WFVisualSans" mb={2}>
            {t('auth.createIdTitle')}
          </Typography>
          <Typography fontWeight={400} mb={editId ? 7 : 4}>
            {t('auth.createIdSubtitle')}
          </Typography>
          <Box display="flex" flexDirection="column">
            {editId && <EditId />}
            {!editId && (
              <>
                <Typography variant="body2" mb={2}>
                  {emailId.length < MAX_ID_LENGTH ? t('auth.createIdText') : t('auth.createIdTextOverLimit')}
                </Typography>

                <StyledIdBox display="flex" justifyContent="center" alignItems="center">
                  {!isLoadingInitialEmailCheck && (<>
                    <StyledEmailIdTypography>{emailId}</StyledEmailIdTypography>
                    {(!downSm || emailId.length <= 14) && (
                      <Typography color={alpha('#000', 0.6)}>{EMAIL_DOMAIN}</Typography>
                    )}
                  </>)}
                  {isLoadingInitialEmailCheck && <Typography color={alpha('#000', 0.6)}>{t('common.checking')}</Typography>}
                </StyledIdBox>

                {downSm && emailId.length > 14 && (
                  <Typography mt={0.5} color={alpha('#000', 0.6)}>
                    {EMAIL_DOMAIN}
                  </Typography>
                )}

                <Box mt="14px">
                  <StyledEditBox onClick={toggleEdit} display="flex" gap={1}>
                    <Typography fontSize={14}>{t('auth.editIdText')}</Typography>
                  </StyledEditBox>
                </Box>
              </>
            )}
          </Box>
          {!editId && (
            <LoadingButton
              fullWidth
              disabled={Object.keys(errors).length > 0}
              variant="contained"
              color="primary"
              loading={loading}
              aria-label="create capsure id confirm button"
              sx={{ mt: 3 }}
              onClick={handleCreate}
            >
              {t('auth.continue')}
            </LoadingButton>
          )}
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(CreateIDForm);
