import { FC, memo, PropsWithChildren } from 'react';
import enAu from 'date-fns/locale/en-AU';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DataPickerLocalizationProvider: FC<PropsWithChildren> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAu}>
    {children}
  </LocalizationProvider>
);

export default memo(DataPickerLocalizationProvider);
