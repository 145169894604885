import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { TaskView } from '@pages/Tasks/enums';
import { StyledApplyToAllTitleBox, StyledIconButton, StyledModal } from '@pages/Tasks/styled';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface ApplyToAllModalProps {
  handleApplyToAll: (date: Date) => void;
  view: TaskView;
}

const ApplyToAllModal = NiceModal.create(({ handleApplyToAll, view }: ApplyToAllModalProps) => {
  const [dateVal, setDateVal] = useState<Date | null>(null);
  const { t } = useTranslation();
  const modal = useModal();
  const { downSm } = useBreakpoints();

  const handleConfirmClick = async () => {
    if (dateVal) {
      handleApplyToAll(dateVal);
    }
    modal.resolve(true);
    modal.remove();
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  const modalTitle = view === TaskView.CLAIM ? t('tasks.list.claim') : t('tasks.list.paid');
  const datePickerLabel =
    view === TaskView.CLAIM ? t('tasks.modal.applyToAll.claimDate') : t('tasks.modal.applyToAll.paidDate');

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <StyledApplyToAllTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {t('tasks.modal.applyToAll.title', { modalTitle })}
        </Typography>

        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledApplyToAllTitleBox>
      <Box padding="24px 20px 30px">
        <Typography fontWeight={400}>
          <Trans
            i18nKey={t('tasks.modal.applyToAll.text')}
            values={{
              viewTextLocale:
                view === TaskView.CLAIM ? t('tasks.modal.applyToAll.claims') : t('tasks.modal.applyToAll.payments'),
            }}
          />
        </Typography>

        <Box mt={4} sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
          <DatePicker
            label={datePickerLabel}
            value={dateVal}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              textField: () => ({
                sx: {
                  fontWeight: 400,
                  '& .MuiInputBase-root': {
                    fontWeight: 400,
                  },
                  '& .MuiFormLabel-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                },
                variant: 'outlined',
              }),
            }}
            onChange={newValue => setDateVal(newValue)}
          />
        </Box>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
        <StyledTextButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          color="primary"
          onClick={handleCancelClick}
          sx={{ height: 36 }}
        >
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>
        <Button
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="contained"
          color="primary"
          disabled={!dateVal}
          onClick={handleConfirmClick}
          sx={{ height: 36, maxWidth: 140, boxShadow: 2 }}
        >
          <Typography fontSize={14}>{t('tasks.modal.applyToAll.applyBtn')}</Typography>
        </Button>
      </Box>
    </StyledModal>
  );
});

export const ApplyToAllModalId = 'ApplyToAllModal';

NiceModal.register(ApplyToAllModalId, ApplyToAllModal);
