import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PaginatedInvoiceListRead } from '@api/api';
import { DAYS_PER_WEEK } from '@constants/common';
import DayInvoicesPopper from '@pages/Invoices/components/CalendarView/DayInvoicesPopper';
import QuickInfoPopper from '@pages/Invoices/components/CalendarView/QuickInfoPopper';
import { ICONS } from '@pages/Invoices/enums';
import { useGroupedByDateInvoices } from '@pages/Invoices/hooks/useGroupedByDateInvoices';
import {
  StyledCellContainer,
  StyledChipDayOfService,
  StyledDesktopInvoiceRow,
  StyledSelectedDesktopDayOfWeekCircle,
  StyledSemiGreyTypography,
  StyledTruncatedTypographyContrast,
} from '@pages/Invoices/styled';
import { ExtendedActiveParticipant, ExtendedInvoice } from '@pages/Invoices/types';
import {
  findSelectedCell,
  generateCalendarData,
  getCurrentDayOfService,
  getFinalDayOfService,
} from '@pages/Invoices/utils';
import { getMonthFull } from '@utils/formatTime';

import CircleIcon from '@mui/icons-material/Circle';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';

interface InvoicesListDesktopProps {
  selectedMonth: string;
  pickedDate: string | null;
  activeParticipants: ExtendedActiveParticipant[] | [];
  invoicesData: PaginatedInvoiceListRead;
}

const InvoicesListDesktop: FC<InvoicesListDesktopProps> = props => {
  const { selectedMonth, pickedDate, activeParticipants, invoicesData } = props;
  const [selectedCell, setSelectedCell] = useState<number | null>(null);
  const { t } = useTranslation();
  const { listToRender, groupedByDate } = useGroupedByDateInvoices(invoicesData);

  const [anchorQuickInfoPopper, setAnchorQuickInfoPopper] = useState<HTMLButtonElement | null>(null);
  const [openQuickInfoPopper, setOpenQuickInfoPopper] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<null | ExtendedInvoice>(null);

  const [openDayInvoicesPopper, setOpenDayInvoicesPopper] = useState(false);
  const [anchorDayInvoicesPopper, setAnchorDayInvoicesPopper] = useState<HTMLElement | null>(null);
  const [selectedDayMoreInvoices, setSelectedMoreDayInvoices] = useState<ExtendedInvoice[]>([]);

  const calendarGrid = generateCalendarData(selectedMonth);

  const handleShowQuickInfoPopper = useCallback(
    (event: MouseEvent<any>, invoice?: ExtendedInvoice) => {
      if (openQuickInfoPopper) {
        // Update info and role for open state
        setSelectedInvoice(invoice || null);
        setAnchorQuickInfoPopper(event.currentTarget);
      } else {
        setSelectedInvoice(invoice || null);
        setAnchorQuickInfoPopper(event.currentTarget);
        setOpenQuickInfoPopper(true);
      }
    },
    [openQuickInfoPopper],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseDayInvoice = useCallback((_: MouseEvent<any>) => {
    setOpenDayInvoicesPopper(false);
    setAnchorDayInvoicesPopper(null);
    setSelectedInvoice(null);
    setAnchorQuickInfoPopper(null);
  }, []);

  useEffect(() => {
    if (pickedDate) {
      const [pickedYear, pickedMonth] = pickedDate.split('-');
      const [currentYear, currentMonth] = selectedMonth.split('-');

      if (pickedYear === currentYear && pickedMonth === currentMonth) {
        const [, , day] = pickedDate.split('-');
        const dayNumber = parseInt(day, 10);

        const targetCells = document.querySelectorAll('.cell-container');

        const matchingCell = Array.from(targetCells).find(cell => {
          const dayElement = cell.querySelector('p.MuiTypography-body1, p.MuiTypography-root');
          const cellDayText = dayElement?.textContent;

          // Convert to number to handle potential leading zeros
          const cellDay = cellDayText ? parseInt(cellDayText, 10) : null;

          // Find the cell that matches the day, excluding previous month's overlapping days
          const cellIndex = Array.from(targetCells).indexOf(cell);
          return cellDay === dayNumber && !(cellIndex < DAYS_PER_WEEK && dayNumber >= 25 && dayNumber <= 31);
        });

        if (matchingCell) {
          matchingCell.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          const clickableElement = matchingCell as HTMLElement;
          clickableElement.click();
        }
      }
    }
  }, [pickedDate, selectedMonth]);

  return (
    <Box>
      {listToRender.length < 1 && (
        <Box mt="12px" display="flex" width="100%" alignItems="center">
          <Typography fontSize={13} fontWeight={400} color="textSecondary">
            <Trans i18nKey={t('dashboard.noRecordsInMonth')} values={{ month: getMonthFull(selectedMonth) }} />
          </Typography>
        </Box>
      )}
      <Box mt="12px" display="grid" gridTemplateColumns={`repeat(${DAYS_PER_WEEK}, 1fr)`}>
        {calendarGrid.map((cell, index) => {
          const isSelected = !!(selectedCell && selectedCell === cell.id);

          const [year, month] = selectedMonth.split('-');

          // generate the date key based on selectedMonth and cell's day
          const formattedDayOfMonth = cell.dayOfMonth.toString().padStart(2, '0');
          const matchingDateKey = `${year}-${month}-${formattedDayOfMonth}`;

          // find invoices for this specific date
          const invoicesForDay = groupedByDate[matchingDateKey] || [];
          const firstInvoice = invoicesForDay[0];
          const secondInvoice = invoicesForDay[1];

          const participantMatches = activeParticipants.filter(
            ap =>
              `${ap.first_name} ${ap.last_name}` === firstInvoice?.participant ||
              `${ap.first_name} ${ap.last_name}` === secondInvoice?.participant,
          );

          const firstInvoiceRole = participantMatches.find(
            pm => `${pm.first_name} ${pm.last_name}` === firstInvoice?.participant,
          )?.role;

          const secondInvoiceRole = participantMatches.find(
            pm => `${pm.first_name} ${pm.last_name}` === secondInvoice?.participant,
          )?.role;

          const showParticipants = !!(
            activeParticipants.length > 1 &&
            (firstInvoice?.participant || secondInvoice?.participant) &&
            participantMatches.length > 0
          );

          const firstInvoiceWithRange = !!(
            firstInvoice &&
            firstInvoice.service_start_date &&
            firstInvoice.service_end_date
          );
          const secondInvoiceWithRange = !!(
            secondInvoice &&
            secondInvoice.service_start_date &&
            secondInvoice.service_end_date
          );

          const showFirstTwo = firstInvoice && !firstInvoiceWithRange && secondInvoice && !secondInvoiceWithRange;
          const isFirstCellOfLastRow =
            (index % DAYS_PER_WEEK === 0 && cell.dayOfMonth >= 25) || (calendarGrid.length === 35 && index === 28);

          return (
            // MAIN CELL BOX
            <StyledCellContainer
              key={cell.id}
              index={index}
              isSelected={isSelected}
              cell={cell}
              isFirstCellOfLastRow={isFirstCellOfLastRow}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => {
                if (cell.isDisabled) return;
                setSelectedCell(cell.id);
              }}
              className="cell-container"
              length={calendarGrid.length}
            >
              {/* TOP PART: DAYOFWEEK + DAY */}
              <Box textAlign="center">
                {index < DAYS_PER_WEEK && (
                  <StyledSemiGreyTypography fontSize={14}>{cell.dayOfWeek}</StyledSemiGreyTypography>
                )}
                {isSelected ? (
                  <StyledSelectedDesktopDayOfWeekCircle>{cell.dayOfMonth}</StyledSelectedDesktopDayOfWeekCircle>
                ) : (
                  <StyledSemiGreyTypography fontWeight={400} fontSize={14}>
                    {cell.dayOfMonth}
                  </StyledSemiGreyTypography>
                )}
              </Box>

              {/* BOTTOM PART  */}
              <Box>
                {firstInvoice && !cell.isDisabled && (
                  <StyledDesktopInvoiceRow
                    key={firstInvoice.invoice_id}
                    display="flex"
                    width="100%"
                    flexDirection="column"
                    role={firstInvoiceRole}
                    onClick={e => {
                      handleShowQuickInfoPopper(e, firstInvoice);
                    }}
                  >
                    {firstInvoice.support_date && firstInvoice.service_start_date && firstInvoice.service_end_date && (
                      <Box mb={1} position="relative" alignSelf="center">
                        <StyledChipDayOfService
                          label={`Day ${getCurrentDayOfService(firstInvoice.support_date, firstInvoice.service_start_date)} of ${getFinalDayOfService(firstInvoice.service_start_date, firstInvoice.service_end_date)}`}
                          color="secondary"
                          size="small"
                          variant="filled"
                        />
                      </Box>
                    )}
                    <Box display="grid" gridTemplateColumns="1fr 20px" gap="5px">
                      <StyledTruncatedTypographyContrast
                        fontSize={13}
                        fontWeight={400}
                        linesLimit={
                          !showFirstTwo && !firstInvoiceWithRange
                            ? 4
                            : firstInvoice.service_start_date && firstInvoice.service_end_date
                              ? 2
                              : 1
                        }
                      >
                        {firstInvoice?.description || t('common.noDescription')}
                      </StyledTruncatedTypographyContrast>
                      <Box>
                        {showParticipants &&
                          firstInvoiceRole &&
                          (firstInvoiceRole === ICONS.CIRCLE ? (
                            <CircleIcon fontSize="small" />
                          ) : (
                            <StarIcon fontSize="small" />
                          ))}
                      </Box>
                    </Box>
                  </StyledDesktopInvoiceRow>
                )}
                {secondInvoice && !firstInvoiceWithRange && !secondInvoiceWithRange && !cell.isDisabled && (
                  <StyledDesktopInvoiceRow
                    mt="6px"
                    key={secondInvoice.invoice_id}
                    display="flex"
                    width="100%"
                    flexDirection="column"
                    role={secondInvoiceRole}
                    onClick={e => {
                      handleShowQuickInfoPopper(e, secondInvoice);
                    }}
                  >
                    <Box display="grid" gridTemplateColumns="1fr 20px" gap="5px">
                      <StyledTruncatedTypographyContrast
                        fontSize={13}
                        fontWeight={400}
                        linesLimit={invoicesForDay.length > 2 ? 1 : 2}
                      >
                        {secondInvoice?.description || t('common.noDescription')}
                      </StyledTruncatedTypographyContrast>
                      <Box>
                        {showParticipants &&
                          secondInvoiceRole &&
                          (secondInvoiceRole === ICONS.CIRCLE ? (
                            <CircleIcon fontSize="small" />
                          ) : (
                            <StarIcon fontSize="small" />
                          ))}
                      </Box>
                    </Box>
                  </StyledDesktopInvoiceRow>
                )}
                {invoicesForDay &&
                  !cell.isDisabled &&
                  invoicesForDay.length > 1 &&
                  invoicesForDay.length > (firstInvoiceWithRange || secondInvoiceWithRange ? 1 : 2) && (
                    <Box
                      mt="6px"
                      textAlign="center"
                      sx={{ cursor: 'pointer' }}
                      className="view-more"
                      onClick={e => {
                        setSelectedMoreDayInvoices(invoicesForDay);
                        setAnchorDayInvoicesPopper(e.currentTarget);
                        setOpenDayInvoicesPopper(true);

                        // hide QuickInfo popper if clicked on another view more
                        setSelectedInvoice(null);
                        setAnchorQuickInfoPopper(null);
                      }}
                    >
                      <Typography fontSize={13} color="textSecondary">
                        <Trans
                          i18nKey={t('dashboard.invoicesMore')}
                          values={{
                            amount: invoicesForDay.length - (firstInvoiceWithRange || secondInvoiceWithRange ? 1 : 2),
                          }}
                        />
                      </Typography>
                    </Box>
                  )}
              </Box>
            </StyledCellContainer>
          );
        })}
      </Box>
      {/* QuickInfoPopper */}
      {selectedInvoice && (
        <QuickInfoPopper
          open={openQuickInfoPopper}
          anchorEl={anchorQuickInfoPopper}
          handleOpen={handleShowQuickInfoPopper}
          invoice={selectedInvoice}
        />
      )}

      {/* DayInvoicesPopper */}
      {openDayInvoicesPopper && (
        <DayInvoicesPopper
          invoicesForDay={selectedDayMoreInvoices}
          openDayInvoice={openDayInvoicesPopper}
          dayInvoiceAnchorEl={anchorDayInvoicesPopper}
          handleShowQuickInfoPopper={handleShowQuickInfoPopper}
          activeParticipants={activeParticipants}
          cell={findSelectedCell(calendarGrid, selectedDayMoreInvoices)[0]}
          onClose={handleCloseDayInvoice}
        />
      )}
    </Box>
  );
};

export default memo(InvoicesListDesktop);
