import { Dispatch, FC, memo, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { SelectDateModalId } from '@pages/Invoices/modals/SelectDateModal';
import { StyledSpeedDial } from '@pages/Invoices/styled';
import { getTodayIso } from '@pages/Invoices/utils';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import { Backdrop, Box, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';

import './speedDial.css';

const SpeedDialButton: FC<{
  handleSetMonth: Dispatch<SetStateAction<string>>;
  handlePickDate: (date: string) => void;
}> = ({ handleSetMonth, handlePickDate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const handleOpenDateModal = () => {
    NiceModal.show(SelectDateModalId, { handleSelectDate: handleSetMonth, handlePickDate });
  };

  const actions = [
    {
      icon: (
        <Box display="flex" gap={1}>
          <Typography fontSize={14}>{t('dashboard.selectDate')}</Typography>
          <EventIcon />
        </Box>
      ),
      name: t('dashboard.selectDate'),
      onClick: () => {
        handleOpenDateModal();
        handleClose();
      },
    },
    {
      icon: (
        <Box display="flex" gap={1}>
          <Typography fontSize={14}>{t('dashboard.today')}</Typography>
          <CalendarTodayIcon />
        </Box>
      ),
      name: t('dashboard.today'),
      onClick: () => {
        handleSetMonth(getTodayIso());
        handlePickDate(getTodayIso());
        handleClose();
      },
    },
  ];

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          zIndex: 2000,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          height: '100vh',
        }}
      />
      <Box>
        <StyledSpeedDial
          ariaLabel="SpeedDial controlled"
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map(action => (
            <SpeedDialAction
              TooltipClasses={{
                tooltip: 'speed-action-tooltip-hide',
              }}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))}
        </StyledSpeedDial>
      </Box>
    </>
  );
};

export default memo(SpeedDialButton);
