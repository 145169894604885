import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPillBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: '#F4FEFF',
  '&:hover': {
    backgroundColor: '#F4FEFF',
  },
  border: `1px solid ${theme.colors.info.dark}`,
  borderRadius: '100px',
  padding: '6px 19px',
  '& svg': {
    color: theme.colors.info.dark,
  },
}));

export const StyledPillTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.contrast,
  fontSize: '14px',
}));

export const StyledSubscribeNowButton = styled(Button)<ButtonProps>({
  height: 42,
  marginTop: '56px',
});

export const StyledSwitchPlanBox = styled(Box)<BoxProps>(({ theme }) => ({
  border: `2px solid ${theme.colors.primary.main}`,
  width: '100%',
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
}));
