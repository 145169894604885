import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthUserParticipantsPartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { editParticipantNameSchema } from '@pages/Settings/schema';
import {
  StyledIconButton,
  StyledModal,
  StyledModalTitleBox,
  StyledParticipantAlert,
  StyledTextField,
} from '@pages/Settings/styled';
import { EditParticipantNameSchema } from '@pages/Settings/types';
import { getErrorKeys, getErrorText } from '@utils/getError';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { ErrorObj } from '@/shared/types';

const EditParticipantNameModal = NiceModal.create(
  ({ firstName, lastName, id }: { firstName: string; lastName: string; id: number }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const snackbar = useSnackbar();
    const { downSm } = useBreakpoints();

    const [patchParticipantMutation, { isLoading }] = useAuthUserParticipantsPartialUpdateMutation();

    const form = useForm<EditParticipantNameSchema>({
      resolver: yupResolver(editParticipantNameSchema),
      defaultValues: {
        first_name: firstName,
        last_name: lastName,
      },
      reValidateMode: 'onChange',
      mode: 'onChange',
    });

    const handleConfirmClick: SubmitHandler<EditParticipantNameSchema> = async ({ first_name, last_name }) => {
      try {
        await patchParticipantMutation({
          id,
          patchedParticipantRequest: {
            ...(first_name && first_name !== firstName && { first_name }),
            ...(last_name && last_name !== lastName && { last_name }),
          },
        });
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, getErrorText(getErrorKeys(err as ErrorObj))), { variant: ERROR });
      } finally {
        modal.resolve(true);
        modal.remove();
      }
    };

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
        <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t('settings.modal.editParticipantName.title')}
          </Typography>
          <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
            <CloseIcon />
          </StyledIconButton>
        </StyledModalTitleBox>

        <Box padding="24px 20px 20px">
          <Box>
            <StyledParticipantAlert variant="filled" severity="warning">
              {t('settings.modal.editParticipantName.notificationText')}
            </StyledParticipantAlert>
          </Box>

          <Box mt={4}>
            <FormProvider {...form}>
              <form autoComplete="off" onSubmit={form.handleSubmit(handleConfirmClick, err => console.log(err))}>
                <Box width="100%">
                  <Box>
                    <Controller
                      name="first_name"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <StyledTextField
                          fullWidth
                          variant="outlined"
                          label={t('common.inputs.firstName')}
                          type="text"
                          autoComplete="off"
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error &&
                            t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                          }
                          {...field}
                        />
                      )}
                    />
                  </Box>

                  <Box mt={3}>
                    <Controller
                      name="last_name"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <StyledTextField
                          fullWidth
                          variant="outlined"
                          label={t('common.inputs.lastName')}
                          type="text"
                          autoComplete="off"
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error &&
                            t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                          }
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Divider sx={{ mt: '30px', mb: '20px', mr: '-50px', ml: '-50px' }} />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <StyledTextButton
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={handleCancelClick}
                    variant="text"
                    sx={{ height: '36px' }}
                  >
                    <Typography fontSize={14}>{t('common.cancel')}</Typography>
                  </StyledTextButton>
                  <LoadingButton
                    fullWidth
                    disableRipple
                    disableElevation
                    disableTouchRipple
                    disableFocusRipple
                    variant="contained"
                    loading={isLoading}
                    color="primary"
                    type="submit"
                    sx={{ height: '36px', boxShadow: 2, width: '75px' }}
                    disabled={!!Object.keys(form.formState.errors).length}
                  >
                    <Typography>{t('common.save')}</Typography>
                  </LoadingButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const EditParticipantNameModalId = 'EditParticipantNameModal';

NiceModal.register(EditParticipantNameModalId, EditParticipantNameModal);
