import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useUser } from '@hooks/api/useUser';
import { useSessionId } from '@hooks/useSessionId';

import { Box, Typography } from '@mui/material';

import { NotificationModalId } from '@/shared/NotificationModal/NotificationModal';

const Home: FC = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading: isLoadingUser } = useUser();
  const { sessionId, removeSessionId } = useSessionId();

  useEffect(() => {
    if (userData && sessionId) {
      NiceModal.show(NotificationModalId, {
        title: <Trans i18nKey="dashboard.notifications.subscribe.success.modalTitle" />,
      });
      removeSessionId();
    }
  }, [sessionId, removeSessionId, t, userData]);

  if (isLoadingUser) return null;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.home')}</title>
      </Helmet>
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Typography color="primary" variant="h2">
          {t('common.comingSoon')}
        </Typography>
      </Box>
    </>
  );
};

export default memo(Home);
