import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_BAR_HEIGHT } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useSubscription } from '@hooks/useSubscription';
import CustomNumber from '@pages/Tasks/components/CustomNumber';
import { TaskView } from '@pages/Tasks/enums';
import { StyledTab, StyledToggleBox, StyledViewTabs } from '@pages/Tasks/styled';
import { ToggleBarProps } from '@pages/Tasks/types';

import { Typography } from '@mui/material';

const ControlsBar: FC<ToggleBarProps> = ({ view, onChange, unclaimed, unpaid }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();
  const { isTrial } = useSubscription();

  return (
    <StyledToggleBox
      aria-label="tasks-toggle-bar"
      display="flex"
      justifyContent="space-between"
      padding={{
        xs: '18px 16px 0',
        sm: '18px 30px 0',
        md: '18px 30px 0',
        lg: '18px 80px 0',
      }}
      width="100%"
      top={isTrial ? 41 + APP_BAR_HEIGHT : APP_BAR_HEIGHT}
      zIndex={1500}
    >
      <StyledViewTabs value={view} onChange={onChange} downSm={downSm}>
        <StyledTab
          value={TaskView.CLAIM}
          label={<Typography>{t('dashboard.claimView')}</Typography>}
          iconPosition="end"
          icon={<CustomNumber amount={unclaimed} isActive={view === TaskView.CLAIM} />}
        />
        <StyledTab
          value={TaskView.PAY}
          label={<Typography>{t('dashboard.payView')}</Typography>}
          iconPosition="end"
          icon={<CustomNumber amount={unpaid} isActive={view === TaskView.PAY} />}
        />
      </StyledViewTabs>
    </StyledToggleBox>
  );
};

export default memo(ControlsBar);
