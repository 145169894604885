import { FC, memo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { SettingsTab } from '@pages/Settings/constants/enums';
import { StyledSettingsHeaderBox } from '@pages/Settings/styled';
import { StyledTab, StyledViewTabs } from '@pages/Tasks/styled';

import { Typography } from '@mui/material';

interface SettingsHeaderProps {
  view: string;
  onChange: (event: SyntheticEvent, value: SettingsTab) => void;
}

const SettingsHeader: FC<SettingsHeaderProps> = ({ view, onChange }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();

  return (
    <StyledSettingsHeaderBox
      m={0}
      pt="19.5px"
      px={{
        xs: 2,
        sm: 2.5,
        md: '30px',
        lg: 10,
      }}
      height={62}
      display="flex"
      gap="58px"
      width="100%"
    >
      <StyledViewTabs value={view} onChange={onChange} downSm={downSm}>
        <StyledTab value={SettingsTab.Account} label={<Typography>{t('settings.account')}</Typography>} />
        <StyledTab value={SettingsTab.Billing} label={<Typography>{t('settings.billing')}</Typography>} />
      </StyledViewTabs>
    </StyledSettingsHeaderBox>
  );
};

export default memo(SettingsHeader);
