import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesEventsListApiResponse } from '@api/api';
import { StyledTimelineActionRow } from '@pages/InvoiceDetails/components/TasksTab/styled';
import { ManualEventActions } from '@pages/InvoiceDetails/components/TasksTab/types';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import AddIcon from '@mui/icons-material/Add';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { StyledTypographyContrast } from '@/shared/styles';

interface TimelineActionsProps {
  isClaimed: boolean;
  isPaid: boolean;
  addEvent: (eventType: ManualEventActions) => Promise<void>;
  deleteTimelineEntry: ({
    eventId,
    eventTitle,
    taskLabel,
  }: {
    eventId: number;
    eventTitle: string;
    taskLabel: string;
  }) => Promise<void>;
  eventsList?: ApiInvoicesEventsListApiResponse;
}

const TimelineActions: FC<TimelineActionsProps> = ({
  eventsList,
  isClaimed,
  isPaid,
  addEvent,
  deleteTimelineEntry,
}) => {
  const { t } = useTranslation();

  const isClaimedId = eventsList?.find(ev => ev.type === 'claim_submitted')?.id;
  const isPaidId = eventsList?.find(ev => ev.type === 'invoice_paid')?.id;
  const isPaymentDue = eventsList?.find(ev => ev.type === 'payment_due_date');

  return (
    <Box
      width={{
        xs: '100%',
        md: '366px',
      }}
      minWidth={{
        xs: '358px',
      }}
    >
      <StyledTimelineActionRow
        width="100%"
        isChecked={isClaimed}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledTypographyContrast fontFamily="WFVisualSans">
          {t('dashboard.tasks.checkboxes.submitClaimLabel')}
        </StyledTypographyContrast>
        <FormControlLabel
          control={
            <Checkbox
              checked={isClaimed}
              onChange={event => {
                if (event.target.checked) {
                  return addEvent('claim_submitted');
                }
                if (isClaimedId) {
                  deleteTimelineEntry({
                    eventId: isClaimedId,
                    eventTitle: t('dashboard.tasks.checkboxes.submitClaimLabel'),
                    taskLabel: t('dashboard.tasks.claimSubmitted'),
                  });
                }
              }}
            />
          }
          label={
            <Typography fontWeight={400} fontFamily="WFVisualSans">
              {t('common.done').toLowerCase()}
            </Typography>
          }
        />
      </StyledTimelineActionRow>
      <StyledTimelineActionRow
        width="100%"
        isChecked={isPaid}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledTypographyContrast fontFamily="WFVisualSans">
          {t('dashboard.tasks.checkboxes.payProviderLabel')}
        </StyledTypographyContrast>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPaid}
              onChange={event => {
                if (event.target.checked) {
                  return addEvent('invoice_paid');
                }
                if (isPaidId) {
                  deleteTimelineEntry({
                    eventId: isPaidId,
                    eventTitle: t('dashboard.tasks.checkboxes.payProviderLabel'),
                    taskLabel: t('dashboard.tasks.providerPaid'),
                  });
                }
              }}
            />
          }
          label={
            <Typography fontWeight={400} fontFamily="WFVisualSans">
              {t('common.done').toLowerCase()}
            </Typography>
          }
        />
      </StyledTimelineActionRow>

      <Box mt="10px" pl="15px">
        {!isPaymentDue?.date && (
          <StyledTextButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="text"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => addEvent('payment_due_date')}
          >
            <Typography fontSize={13}>{t('dashboard.tasks.buttons.addDueDate')}</Typography>
          </StyledTextButton>
        )}
      </Box>
    </Box>
  );
};

export default memo(TimelineActions);
