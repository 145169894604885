import { ROUTING } from '@constants/routing';

export const navConfig = [
  {
    title: 'Home',
    path: ROUTING.ROOT,
  },
  {
    title: 'Invoices',
    path: `${ROUTING.ROOT}${ROUTING.INVOICES}`,
  },
  {
    title: 'Tasks',
    path: `${ROUTING.ROOT}${ROUTING.TASKS}`,
  },
];
