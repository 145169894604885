import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import {
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  IconButtonProps,
  Link,
  LinkProps,
  MenuItem,
  MenuItemProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';

export const StyledAppLayoutWrapper = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.white,
}));

export const StyledNavigationIdBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.light,
  padding: '10px 15px',
  textAlign: 'center',
  height: '45px',
  borderRadius: '4px',
}));

export const StyledLink = styled(Link)<LinkProps>(() => ({
  textDecoration: 'none',
  color: 'black',
}));

export const StyledTransitionBox = styled(Box)<BoxProps>({
  transition: 'width .25s ease',
});

export const StyledTextOverflowBox = styled(Box)<BoxProps>({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
});

export const StyledPrimaryAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  backgroundColor: theme.colors.primary.main,
  height: 40,
  width: 40,
}));

export const StyledTrialBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.contrast,
  color: theme.colors.white,
  maxHeight: 41,
  paddingTop: '20px',
  paddingBottom: '20px',
  boxShadow: '0 3px 5px -1px gray',
}));

export const StyledSubscribeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.white,
  padding: 0,
}));

export const StyledShareBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  border: 'transparent',
  outline: 'transparent',
  boxShadow: 'none',
  color: theme.colors.primary.main,
  height: 30,
  width: 100,
  cursor: 'pointer',
}));

export const StyledMenuItemSelectableText = styled(MenuItem)<MenuItemProps>({
  cursor: 'text',
  pointerEvents: 'stroke',
  backgroundColor: 'transparent',
  userSelect: 'text',
  '-webkit-user-select': 'text',
  '-moz-user-select': 'text',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>({
  pointerEvents: 'stroke',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledCopyButton = styled(Button)<ButtonProps>({
  height: 54,
});

export const StyledCopyTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  textAlign: 'center',
  height: 54,
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    color: theme.colors.black,
    '-webkit-text-fill-color': theme.colors.black,
  },
}));

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'Poppins';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
    &::placeholder {
      font-size: 16px;
      font-weight: 400;
    }

    &:hover {
      border-color: ${theme.colors.primary.main};
    }

    &:focus {
      border-color: ${theme.colors.primary.main};
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
    
    resize: none;
  `,
);

export const StyledUploadIcon = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: '#F4ECFF',
  height: '40px',
  width: '40px',
  borderRadius: '5px',
}));
