import { ChangeEvent, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { ParticipantRole } from '@pages/Settings/modals/AddParticipant/enums';
import { StyledParticipantAlert } from '@pages/Settings/styled';

import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

const RadioOptionsView: FC<{
  value: ParticipantRole;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handleCancel: () => void;
}> = ({ value, onChange, handleNext, handleCancel }) => {
  const { t } = useTranslation();

  const showInitialAlert = value === ParticipantRole.Nominee || value === ParticipantRole.ChildRepresentative;

  return (
    <Box>
      {showInitialAlert && (
        <StyledParticipantAlert variant="filled" severity="warning">
          {t('settings.participants.modal.alertAddNotificationText')}
        </StyledParticipantAlert>
      )}

      <Box mt={3}>
        <Typography fontSize={14} fontWeight={400} color="textSecondary">
          {t('settings.participants.modal.selectOption')}
        </Typography>
      </Box>
      <Box>
        <FormControl>
          <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={onChange}>
            <FormControlLabel
              value={showInitialAlert ? ParticipantRole.Nominee : ParticipantRole.Myself}
              control={<Radio />}
              label={
                <Typography fontSize={14} fontWeight={400}>
                  {t(`settings.participants.modal.options.${showInitialAlert ? 'nominee' : 'addMeAsParticipant'}`)}
                </Typography>
              }
            />
            <FormControlLabel
              value={showInitialAlert ? ParticipantRole.ChildRepresentative : ParticipantRole.Other}
              control={<Radio />}
              label={
                <Typography fontSize={14} fontWeight={400}>
                  {t(
                    `settings.participants.modal.options.${showInitialAlert ? 'childRepresentative' : 'addOtherAsParticipant'}`,
                  )}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {value === ParticipantRole.Other && (
        <Box mt={1}>
          <StyledParticipantAlert variant="filled" severity="warning">
            {t('settings.participants.modal.alertAddNotificationText')}
          </StyledParticipantAlert>
        </Box>
      )}

      <Divider sx={{ mt: '30px', mb: '20px', mr: '-50px', ml: '-50px' }} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledTextButton disableRipple disableFocusRipple disableTouchRipple onClick={handleCancel} variant="text">
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>
        <Button
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="contained"
          color="primary"
          sx={{ maxHeight: '38px', boxShadow: 2, width: '90px' }}
          onClick={handleNext}
        >
          <Typography>{t('common.next')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(RadioOptionsView);
