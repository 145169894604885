import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_BAR_HEIGHT } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useSubscription } from '@hooks/useSubscription';
import { VIEW } from '@pages/Invoices/enums';
import { StyledControlsBox, StyledViewTabs } from '@pages/Invoices/styled';
import { ControlsBarProps } from '@pages/Invoices/types';

import { Tab, Typography } from '@mui/material';

const ControlsBar: FC<ControlsBarProps> = ({ view, onChange }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();
  const { isTrial } = useSubscription();

  return (
    <StyledControlsBox
      aria-label="invoices-controls-bar"
      display="flex"
      justifyContent="space-between"
      padding={{
        xs: '18px 16px 0',
        sm: '18px 30px 0',
        md: '18px 30px 0',
        lg: '18px 80px 0',
      }}
      width="100%"
      top={isTrial ? 41 + APP_BAR_HEIGHT : APP_BAR_HEIGHT}
      zIndex={1500}
    >
      <StyledViewTabs value={view} onChange={onChange} downSm={downSm}>
        <Tab value={VIEW.TABLE} label={<Typography>{t('dashboard.tableView')}</Typography>} />
        <Tab value={VIEW.CALENDAR} label={<Typography>{t('dashboard.calendarView')}</Typography>} />
      </StyledViewTabs>
    </StyledControlsBox>
  );
};

export default memo(ControlsBar);
