import { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH, MAX_UPLOAD_FILES } from '@constants/common';
import { UPLOAD_FILE_ERROR } from '@constants/errors';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFileUpload } from '@hooks/api/useFileUpload';
import { useBreakpoints } from '@hooks/useBreakpoints';
import FileItem from '@shared/UploadFileModal/components/FileItem';
import {
  StyledAddFilesButton,
  StyledFooterTextButton,
  StyledIconButton,
  StyledInvoiceUploadTitleBox,
  StyledUploadBox,
} from '@shared/UploadFileModal/styled';
import { uploadInvoiceSuccess } from '@store/uploadSlice';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, List, ListItem, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledModal } from '../styles';

const UploadFileModal = NiceModal.create(() => {
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const modal = useModal();
  const { downSm } = useBreakpoints();
  const { uploadFileMutation } = useFileUpload();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    modal.resolve(true);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  const handleChooseFile = () => {
    if (uploadingFiles) return;
    fileInputRef.current?.click();
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (addedFiles.length + acceptedFiles.length > MAX_UPLOAD_FILES) {
      const remainingSlots = MAX_UPLOAD_FILES - addedFiles.length;
      const filesToAdd = acceptedFiles.slice(0, remainingSlots);
      setAddedFiles([...addedFiles, ...filesToAdd]);
      snackbar.enqueueSnackbar(t('dashboard.dragAndDropLimitNotification', { maxFiles: MAX_UPLOAD_FILES }), {
        variant: 'warning',
      });
    } else {
      setAddedFiles([...addedFiles, ...acceptedFiles]);
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: MAX_UPLOAD_FILES,
  });

  const handleRemoveFile = (fileIndex: number) => {
    setAddedFiles(addedFiles.filter((_, index) => index !== fileIndex));
  };

  const handleReset = () => setFilesUploaded(false);

  const handleUpload = async () => {
    try {
      setUploadingFiles(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const file of addedFiles) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFileMutation({ body: { file } });
      }
    } catch (error) {
      handleCancelClick();
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      dispatch(uploadInvoiceSuccess());
      setAddedFiles([]);
      setUploadingFiles(false);
      setFilesUploaded(true);
    }
  };

  useEffect(() => {
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.TOO_MANY_FILES) {
      snackbar.enqueueSnackbar(t('dashboard.dragAndDropLimitNotification', { maxFiles: MAX_UPLOAD_FILES }), {
        variant: 'warning',
      });
    }
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.INVALID_FILE_TYPE) {
      snackbar.enqueueSnackbar(fileRejections[0].errors[0].message, {
        variant: 'warning',
      });
    }
  }, [fileRejections, snackbar, t]);

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'medium'} onClose={handleCancelClick}>
      <StyledInvoiceUploadTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {t('dashboard.navigation.modal.uploadTitle')}
        </Typography>

        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledInvoiceUploadTitleBox>

      <Box display="flex" flexDirection="column" height="100%" position="relative" overflow="hidden">
        {!filesUploaded && (
          <Box flexGrow={1} overflow="auto" maxHeight={600} p="24px 20px 0px">
            <Typography fontWeight={400} mb={4}>
              {t('dashboard.navigation.modal.uploadSubtitle')}
            </Typography>
            <Box display="flex" flexDirection="column" justifyContent="center" alignSelf="center" pb="30px">
              <Box
                {...getRootProps()}
                aria-label="Drag and drop zone"
                sx={{ pointerEvents: uploadingFiles ? 'none' : 'inherit' }}
                mb={addedFiles.length > 0 ? 3 : 0}
              >
                <input {...getInputProps()} ref={fileInputRef} aria-label="File input" disabled={uploadingFiles} />
                <StyledUploadBox textAlign="center" display="flex" flexDirection="column" alignItems="center">
                  <Typography fontWeight={400} mb="12px">
                    {t('dashboard.dragAndDropTitle')}
                  </Typography>
                  <StyledAddFilesButton
                    variant="outlined"
                    color="primary"
                    onClick={handleChooseFile}
                    disabled={uploadingFiles}
                  >
                    <Typography fontSize={14}>{t('dashboard.chooseFiles')}</Typography>
                  </StyledAddFilesButton>
                  <Typography fontWeight={400} fontSize={14} mt={4}>
                    ({t('dashboard.dragAndDropFileTypes', { maxFiles: MAX_UPLOAD_FILES })})
                  </Typography>
                </StyledUploadBox>
              </Box>

              {addedFiles.length > 0 && (
                <List aria-label="List of added files" sx={{ padding: '0px !important' }}>
                  {addedFiles.map((file, index) => (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      sx={{
                        padding: '0px !important',
                        margin: '10px 0',
                        marginBottom: index < addedFiles.length - 1 ? '10px' : 0,
                      }}
                    >
                      <FileItem name={file.name} onRemove={() => handleRemoveFile(index)} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Box>
        )}
        {filesUploaded && (
          <Box
            display="flex"
            flexDirection="column"
            p="50px 40px"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography color="primary" fontSize={20} fontFamily="WFVisualSans" mb="12px">
              {t('dashboard.uploadSuccess')}
            </Typography>
            <TaskAltIcon color="primary" fontSize="large" />
            <Typography fontSize={14} fontWeight={400} my={4} maxWidth={520}>
              {t('dashboard.uploadSuccessSubtitle')}
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="textSecondary">
              {t('dashboard.uploadHelperText')}
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          flexShrink={0}
          width="100%"
          p="20px"
          sx={{
            borderTop: '1px solid #e0e0e0',
          }}
        >
          <StyledFooterTextButton
            disableRipple
            onClick={filesUploaded ? handleReset : handleCancelClick}
            variant="text"
          >
            <Typography fontSize={14}>{filesUploaded ? t('dashboard.uploadMore') : t('common.cancel')}</Typography>
          </StyledFooterTextButton>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={filesUploaded ? handleConfirmClick : handleUpload}
            loading={uploadingFiles}
            aria-label={t('dashboard.uploadFiles')}
            aria-disabled={addedFiles.length === 0}
            sx={{ padding: '8px 22px', height: 36, boxShadow: 2 }}
            disabled={filesUploaded ? false : addedFiles.length < 1}
          >
            {filesUploaded ? t('dashboard.uploadClosePopup') : t('dashboard.uploadFiles')}
          </LoadingButton>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const UploadFileModalId = 'UploadFileModal';

NiceModal.register(UploadFileModalId, UploadFileModal);
