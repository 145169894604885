import { useCallback } from 'react';
import { ParserUploadCreateApiArg } from '@api/api';
import { USER_ACCESS_TOKEN } from '@constants/auth';
import { getToken } from '@utils/manipulateStorage';

export const useFileUpload = () => {
  const baseUrl = import.meta.env.VITE_APP_API_URL;
  const uploadFileMutation = useCallback(
    async ({ body }: ParserUploadCreateApiArg) => {
      const formData = new FormData();
      if (body.file) {
        formData.append('file', body.file);
      }
      const token = getToken(USER_ACCESS_TOKEN);

      const response = await fetch(`${baseUrl}/parser/upload/`, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      return response.json();
    },
    [baseUrl],
  );

  return { uploadFileMutation, baseUrl };
};
