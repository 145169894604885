import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { TaskView } from '@pages/Tasks/enums';
import { RemoveFromListModalId } from '@pages/Tasks/modals/RemoveFromListModal';
import {
  StyledActionBarBox,
  StyledFooterTextButton,
  StyledMarkAsClaimedButton,
  StyledRemoveFromListButton,
} from '@pages/Tasks/styled';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';

interface BottomActionBarProps {
  ids: number[];
  handleMark: () => void;
  handleRemove: () => Promise<void>;
  handleUnselect: () => void;
  totalCost: string;
  view: TaskView;
}

const BottomActionBar: FC<BottomActionBarProps> = props => {
  const { ids, handleMark, handleRemove, handleUnselect, totalCost, view } = props;
  const { downMd, downSm } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <StyledActionBarBox
      downMd={downMd}
      p={{
        xs: '24px 16px',
        sm: '24px 20px',
        md: '30px',
        lg: '30px 80px',
      }}
    >
      <Typography fontWeight={400} width={320}>
        <Trans
          i18nKey={ids.length > 1 ? t('tasks.footer.totalSelectedMany') : t('tasks.footer.totalSelectedOne')}
          values={{ amount: ids.length, cost: totalCost }}
          components={{ bold: <strong /> }}
        />
      </Typography>

      <Box display="flex" gap="12px" width={downSm ? '100%' : 'auto'}>
        <StyledMarkAsClaimedButton
          fullWidth={downSm}
          variant="contained"
          endIcon={<CheckIcon sx={{ '& svg': { color: '#5A1ED3' } }} />}
          onClick={handleMark}
        >
          <Typography fontSize={14}>
            {t(`tasks.footer.${view === TaskView.CLAIM ? 'markAsClaimed' : 'markAsPaid'}`)}
          </Typography>
        </StyledMarkAsClaimedButton>
        {!downSm && (
          <StyledRemoveFromListButton
            variant="outlined"
            color="inherit"
            onClick={async () => {
              NiceModal.show(RemoveFromListModalId, {
                view,
                deleteMutation: handleRemove,
              });
            }}
          >
            <Typography fontSize={14}>{t('tasks.footer.removeFromList')}</Typography>
          </StyledRemoveFromListButton>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between" width={downSm ? '100%' : 'auto'}>
        <StyledFooterTextButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          variant="text"
          onClick={handleUnselect}
        >
          <Typography fontSize={14} color="white">
            {t('tasks.footer.unselectAll')}
          </Typography>
        </StyledFooterTextButton>
        {downSm && (
          <StyledFooterTextButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="text"
            onClick={async () => {
              NiceModal.show(RemoveFromListModalId, {
                view,
                deleteMutation: handleRemove,
              });
            }}
          >
            <Typography fontSize={14} color="white">
              {t('tasks.footer.removeFromList')}
            </Typography>
          </StyledFooterTextButton>
        )}
      </Box>
    </StyledActionBarBox>
  );
};

export default memo(BottomActionBar);
