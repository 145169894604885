import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface BillingDetailsRowProps {
  icon: ReactNode;
  detailsTitle: string;
  detailsText: string;
}

const BillingDetailsRow: FC<BillingDetailsRowProps> = props => {
  const { icon, detailsTitle, detailsText } = props;
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" gap={4}>
      {icon}
      <Box aria-label="detail-section">
        <Typography color={alpha('#000', 0.87)} fontWeight={400}>
          {t(detailsTitle)}
        </Typography>
        <Typography color={alpha('#000', 0.6)} fontWeight={400} fontSize={14} mt="6px">
          {t(detailsText)}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(BillingDetailsRow);
