import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { NOT_AVAILABLE } from '@constants/common';
import { StyledSecondaryChip } from '@pages/InvoiceDetails/components/styled';
import { getDueDaysLeft } from '@pages/InvoiceDetails/utils';
import { TaskView } from '@pages/Tasks/enums';
import {
  StyledBottomBox,
  StyledCardBgBox,
  StyledCardPaper,
  StyledChip,
  StyledErrorDueDateChip,
  StyledOutlinedDarkChip,
  StyledTruncatedTypographyContrast,
} from '@pages/Tasks/styled';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { StyledTypographyContrast } from '@/shared/styles';

interface InvoiceCardDetailsProps {
  actionRequired: boolean;
  invoice: InvoiceRead;
  handleViewInvoiceDetails: (invoiceId: number) => VoidFunction;
  handleCheckbox: (id: number) => void;
  getCheckboxState: (invoiceId: number) => boolean;
  view: TaskView;
}

const InvoiceCardDetails: FC<InvoiceCardDetailsProps> = props => {
  const { actionRequired, invoice, handleViewInvoiceDetails, handleCheckbox, getCheckboxState, view } = props;
  const { t } = useTranslation();

  const supportDate =
    !invoice.service_start_date && !invoice.service_end_date && invoice.service_exact_date
      ? convertIsoToReadable(invoice.service_exact_date, { fullYear: true })
      : invoice.service_start_date &&
        invoice.service_end_date &&
        `${convertIsoToReadable(invoice.service_start_date, { fullYear: true })} - ${convertIsoToReadable(invoice.service_end_date, { fullYear: true })}`;

  const isClaimView = view === TaskView.CLAIM;

  const renderClaimViewFirstSlot = () => (
    <>
      {actionRequired ? (
        <StyledSecondaryChip
          color="secondary"
          variant="filled"
          label={t('dashboard.invoiceDetails.incompleteDetails')}
        />
      ) : (
        !invoice?.is_claimed && (
          <StyledChip color="info" variant="outlined" label={t('dashboard.invoiceDetails.readyToClaim')} />
        )
      )}
    </>
  );

  const renderPayViewFirstSlot = () => (
    <>
      {actionRequired ? (
        <StyledSecondaryChip
          color="secondary"
          variant="filled"
          label={t('dashboard.invoiceDetails.incompleteDetails')}
        />
      ) : invoice?.is_claimed ? (
        <StyledChip isDark color="primary" variant="filled" label={t('dashboard.invoiceDetails.claimed')} />
      ) : (
        <StyledChip color="primary" variant="outlined" label={t('dashboard.invoiceDetails.notClaimed')} />
      )}
    </>
  );

  return (
    <StyledCardPaper elevation={2}>
      <StyledCardBgBox p="15px" display="flex" alignItems="center">
        {
          <Box display="flex" gap={1}>
            {/* SLOT_1 */}
            {isClaimView ? renderClaimViewFirstSlot() : renderPayViewFirstSlot()}

            {/* SLOT_3 */}
            {(() => {
              if (invoice.is_paid) {
                return (
                  <StyledChip
                    isDark
                    color="primary"
                    variant="filled"
                    label={t('dashboard.invoiceDetails.paid')}
                    icon={<CheckIcon />}
                  />
                );
              }
              if (!invoice.due_date) {
                return <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />;
              }

              if (invoice.due_date) {
                const dueDays = getDueDaysLeft(invoice.due_date);

                if (dueDays === 0) {
                  return (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueToday')}
                      color="error"
                      variant="outlined"
                    />
                  );
                }
                if (dueDays > 0 && dueDays <= 10) {
                  return (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueSoon')}
                      color="error"
                      variant="outlined"
                    />
                  );
                }

                if (dueDays > 0 && dueDays > 10) {
                  return <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />;
                }
                return (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.overdue')}
                    color="error"
                    variant="filled"
                  />
                );
              }
            })()}
          </Box>
        }
      </StyledCardBgBox>
      <Box padding="16px 15px 20px" width="100%">
        <Box display="flex" gap={1} alignItems="center">
          <CalendarTodayIcon color="action" />
          <StyledTypographyContrast fontSize={16} fontWeight={400}>
            {supportDate || NOT_AVAILABLE}
          </StyledTypographyContrast>
        </Box>

        <Box mt="13px" display="flex" gap={1} alignItems="center">
          <BusinessIcon color="action" />
          <StyledTruncatedTypographyContrast fontSize={18} fontFamily="WFVisualSans" linesLimit={1}>
            {invoice.vendor_name}
          </StyledTruncatedTypographyContrast>
        </Box>

        <Box mt="14px" display="flex" gap={1} alignItems="center" justifyContent="space-between">
          <StyledTruncatedTypographyContrast fontSize={16} fontWeight={400} linesLimit={1}>
            {invoice.description}
          </StyledTruncatedTypographyContrast>
          <StyledTypographyContrast fontFamily="WFVisualSans" fontSize={22}>
            {`${invoice.currency_symbol ? invoice.currency_symbol : ''}${formatCurrency(invoice.total_amount)}`}
          </StyledTypographyContrast>
        </Box>
      </Box>
      <StyledBottomBox p="4px 17px" display="flex" justifyContent="space-between" width="100%">
        <FormControlLabel
          checked={getCheckboxState(invoice.id)}
          control={<Checkbox onChange={() => handleCheckbox(invoice.id)} />}
          labelPlacement="end"
          label={
            <Typography fontSize={14} fontWeight={400} color="#000000DE">
              {t('tasks.select')}
            </Typography>
          }
        />
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleViewInvoiceDetails(invoice.id)}
          color="primary"
          variant="text"
          style={{ justifyContent: 'flex-start', boxShadow: 'none', padding: '4px 5px' }}
        >
          <Typography fontSize={14}>{t('dashboard.quickInfoModal.viewDetailsBtnText')}</Typography>
        </Button>
      </StyledBottomBox>
    </StyledCardPaper>
  );
};

export default memo(InvoiceCardDetails);
