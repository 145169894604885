import {
  Alert,
  AlertProps,
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Dialog,
  DialogProps,
  Divider,
  DividerProps,
  IconButtonProps,
  Paper,
  PaperProps,
  SpeedDial,
  SpeedDialProps,
  Switch,
  SwitchProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';

export const StyledToggleBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.background.secondaryBackground,
  height: '60px',
}));

export const StyledViewTabs = styled(Tabs)<TabsProps & { downSm?: boolean }>(({ downSm }) => ({
  display: 'flex',
  '& .MuiButtonBase-root': {
    fontWeight: 500,
    fontSize: 14,
    padding: downSm ? '0 12px' : '0 16px',
    minHeight: '42px',
    flexGrow: downSm ? '1' : '0',
  },
  '& .MuiTabs-indicator': {
    bottom: '6px',
  },
  '& .MuiTabs-flexContainer': {
    gap: '12px',
  },
  width: '100%',
  justifyContent: downSm ? 'center' : 'start',
}));

export const StyledCustomNumberBadge = styled(Badge)<BadgeProps & { isActive: boolean }>(({ theme, isActive }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isActive ? theme.colors.primary.main : '#E0E0E0',
    color: isActive ? theme.colors.white : theme.colors.secondary.contrast,
  },
}));

export const StyledTab = styled(Tab)<TabProps>({
  '&.MuiButtonBase-root': { gap: '16px' },
});

export const StyledDivider = styled(Divider)<DividerProps>({
  margin: '32px 0px',
});

export const StyledActionBarBox = styled(Box)<BoxProps & { downMd?: boolean }>(({ theme, downMd }) => ({
  position: 'fixed',
  width: '100%',
  bottom: '0px',
  height: downMd ? 198 : 96,
  color: theme.colors.white,
  backgroundColor: theme.colors.primary.dark,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: downMd ? 'column' : 'row',
  alignItems: 'center',
  flexGrow: 1,
}));

export const StyledCardBgBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#FAFAFA',
}));

export const StyledCardPaper = styled(Paper)<PaperProps>({
  backgroundColor: 'white',
  width: '100%',
});

export const StyledChip = styled(Chip)<ChipProps & { isDark?: boolean }>(({ theme, isDark, color }) => ({
  '&.MuiChip-root': {
    height: '24px',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    backgroundColor: isDark ? theme.colors.primary.dark : 'inherit',
    borderColor: color === 'info' ? theme.colors.info.dark : 'inherit',
    color: color === 'info' ? theme.colors.info.dark : 'auto',
  },
}));

export const StyledErrorDueDateChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    fontSize: '13px',
    fontWeight: 400,
    height: '24px',
    border: `1px solid ${theme.colors.error.main} !important`,
  },
}));

export const StyledOutlinedDarkChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    height: '24px',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderColor: `${theme.colors.primary.dark} !important`,
    color: `${theme.colors.primary.dark} !important`,
    background: theme.colors.white,
  },
}));

export const StyledBottomBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.background.detailsBackground,
  color: theme.colors.primary.main,
}));

export const StyledTruncatedTypographyContrast = styled(Typography)<TypographyProps & { linesLimit?: number }>(
  ({ theme, linesLimit }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: linesLimit,
    color: theme.colors.secondary.contrast,
  }),
);

export const StyledMarkAsClaimedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 36,
  backgroundColor: 'white',
  color: theme.colors.primary.dark,
  border: `1px solid ${theme.colors.primary.dark}`,
  '&.MuiButtonBase-root:hover': {
    color: theme.colors.white,
    border: `1px solid ${theme.colors.white}`,
  },
  '&.MuiButtonBase-root:active': {
    backgroundColor: theme.colors.primary.dark,
  },
}));

export const StyledRemoveFromListButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 36,
  backgroundColor: theme.colors.primary.dark,
  color: theme.colors.white,
  border: `1px solid ${theme.colors.white}`,
  '&.MuiButtonBase-root:hover': {
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.primary.dark}`,
  },
}));

export const StyledFooterTextButton = styled(Button)<ButtonProps>(() => ({
  height: 36,
  boxShadow: 'none',
  '&:hover': { boxShadow: 'none' },
}));

export const StyledAlert = styled(Alert)<AlertProps>(() => ({
  marginTop: '24px',
  border: 'none',
  maxWidth: '100%',
  '& .MuiAlert-action': {
    marginLeft: 0,
  },
  '& .MuiAlert-message': {
    flexGrow: 1,
  },
}));

export const StyledFormControlBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  '& .MuiFormControl-root': {
    width: '100%',
  },
}));

export const StyledDeleteEventTitleBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: theme.colors.background.notificationBackground,
  padding: '15px 20px',
  color: theme.colors.action.notificationText,
}));

export const StyledApplyToAllTitleBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: theme.colors.background.detailsBackground,
  padding: '15px 20px',
}));

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: '2px',
});

const mapSizes = {
  xs: {
    '.MuiPaper-root': {
      maxWidth: '358px',
      width: '100%',
    },
  },
  small: {
    '.MuiPaper-root': {
      maxWidth: '430px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1158px',
      height: '85vh',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'xs' | 'small' | 'medium' | 'xl'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.white,
    },
    zIndex: 2000,
    ...(size && mapSizes[size]),
  }),
);

export const StyledSpeedDial = styled(SpeedDial)<SpeedDialProps>(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 16,
  zIndex: 2500,
  alignItems: 'end',
  margin: 0,
  '& .MuiButtonBase-root.MuiFab-sizeLarge': {
    backgroundColor: theme.colors.secondary.main,
  },
  '& svg': { color: '#25175F' },
  '& .MuiSpeedDial-actions': {
    marginRight: '4px',
    '& .MuiButtonBase-root': {
      color: theme.colors.contrast,
      height: '48px',
      width: '48px',
      alignSelf: 'flex-end',
      padding: '10px',
      borderRadius: '100px',
      marginRight: 0,
      '& .MuiBox-root': {
        alignItems: 'center',
      },
    },
  },
}));

export const StyledEmptyListBox = styled(Box)<BoxProps>(() => ({
  height: '410px',
  width: '100%',
  background: 'linear-gradient(#EEEEEE, #FFFFFF)',
}));

export const StyledSwitch = styled(Switch)<SwitchProps>(() => ({
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F',
  },
}));
