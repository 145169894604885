import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import CardsList from '@pages/Tasks/components/CardsList';
import EmptyList from '@pages/Tasks/components/EmptyList';
import SortingGroup from '@pages/Tasks/components/SortingGroup';
import { useTasksContext } from '@pages/Tasks/context';
import { DocumentView } from '@pages/Tasks/enums';
import { ApplyToAllModalId } from '@pages/Tasks/modals/ApplyToAllModal';
import { StyledDivider } from '@pages/Tasks/styled';
import { getTranslationKey } from '@pages/Tasks/utils';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

interface PayViewProps {
  amount: number;
}

const PayView: FC<PayViewProps> = ({ amount }) => {
  const { t } = useTranslation();
  const { downMd, downLg } = useBreakpoints();
  const {
    handleSortBy,
    sortBy,
    documentView,
    unpaidArr,
    handleSameDate,
    isLoadingMarkInvoices,
    handleMarkInvoices,
    editPaidForm,
    handleToggleDocumentView,
    view,
    handleUnselectAll,
  } = useTasksContext();

  const onConfirm = async () => {
    await handleMarkInvoices();
    handleToggleDocumentView();
  };

  const onCancel = () => {
    handleToggleDocumentView();
    handleUnselectAll();
  };

  return (
    <Box>
      <Box width="100%" display="flex" alignItems="center">
        <Box
          maxWidth='100%'
        >
          <Typography fontFamily="WFVisualSans" fontSize={20} mb="5px" lineHeight="32px">
            {documentView === DocumentView.DETAILS ? t('tasks.pay.title') : t('tasks.pay.editorTitle')}
          </Typography>
          <Typography fontWeight={400}>
            {documentView === DocumentView.DETAILS && (
              <Trans i18nKey={getTranslationKey(amount, 'pay')} values={{ amount }} components={{ bold: <strong /> }} />
            )}
            {documentView === DocumentView.EDITOR && t('tasks.pay.subtitle_edit')}
          </Typography>
        </Box>

        {/* SORT BOX */}
        {amount > 0 && !downMd && documentView === DocumentView.DETAILS && (
          <Box display="flex" alignSelf="center" flexDirection="column" alignItems="flex-end" flexGrow={1}>
            <Box>
              <Typography fontSize={13} fontWeight={400} mb="10px">
                {t('tasks.sortList')}
              </Typography>
              <SortingGroup sortValue={sortBy} handleChange={handleSortBy} />
            </Box>
          </Box>
        )}
      </Box>
      {amount > 0 && <StyledDivider />}

      {!downMd && documentView === DocumentView.EDITOR && (
        <Box display="flex" alignSelf="center" flexDirection="column" mb={3}>
          <Button
            variant="outlined"
            sx={{ height: 42, width: 290, boxShadow: 'none' }}
            onClick={() => {
              NiceModal.show(ApplyToAllModalId, {
                handleApplyToAll: handleSameDate,
                view,
              });
            }}
            color="primary"
          >
            <Typography>{t('tasks.pay.allPaidSameDay')}</Typography>
          </Button>
        </Box>
      )}

      <FormProvider {...editPaidForm}>
        <Box component="form" onSubmit={editPaidForm.handleSubmit(onConfirm, err => console.log(err))}>
          <CardsList documentView={documentView} invoices={unpaidArr} />
          {documentView === DocumentView.EDITOR && (
            <Box
              display="flex"
              flexGrow={downLg ? 1 : 0}
              justifyContent={downLg ? 'center' : 'flex-start'}
              flexDirection={downMd ? 'column' : 'row-reverse'}
              gap={{
                xs: 5,
                md: 4,
                lg: 2,
              }}
              mt={7}
              px={{
                xs: 0,
                sm: '60px',
                md: 0,
              }}
            >
              <LoadingButton
                fullWidth={downLg}
                loading={isLoadingMarkInvoices}
                endIcon={<CheckIcon />}
                color="primary"
                variant="contained"
                sx={{ height: 42 }}
                type="submit"
              >
                <Typography>{t('tasks.pay.markAllPaid')}</Typography>
              </LoadingButton>
              <Button
                variant={downMd ? 'text' : 'outlined'}
                sx={{ height: 42, boxShadow: 'none' }}
                type="button"
                onClick={onCancel}
              >
                <Typography>{t('common.cancel')}</Typography>
              </Button>
            </Box>
          )}
        </Box>
      </FormProvider>

      {/* EMPTY STATE */}
      {amount < 1 && (
        <Box mt={5}>
          <EmptyList view={view} />
        </Box>
      )}
    </Box>
  );
};

export default memo(PayView);
