import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useTabs } from '@hooks/useTabs';

import { Box, Tab, Typography } from '@mui/material';

import { Indicators } from '../enums';

import { StyledTabs, StyledToggleBox } from './styled';

const config = [
  { label: 'common.titles.details', value: Indicators.DETAILS },
  { label: 'common.titles.tasks', value: Indicators.TASKS },
  { label: 'common.titles.notes', value: Indicators.NOTES },
];

const ToggleTabs: FC = () => {
  const { t } = useTranslation();
  const { tab, handleChange } = useTabs(Indicators.DETAILS, Indicators);

  const { upLg, downSm } = useBreakpoints();

  return (
    <Box p={0} m={0}>
      <StyledToggleBox px={upLg ? '80px' : '20px'} pt="20px">
        <StyledTabs
          value={tab}
          onChange={handleChange}
          aria-label="invoice details tabs"
          textColor="primary"
          indicatorColor="primary"
          downSm={downSm}
        >
          {config.map(v => (
            <Tab value={v.value} label={<Typography>{t(v.label)}</Typography>} key={v.value} color="primary" />
          ))}
        </StyledTabs>
      </StyledToggleBox>
    </Box>
  );
};

export default memo(ToggleTabs);
