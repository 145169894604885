import { AccordionDetailsProps, AccordionProps, AccordionSummaryProps, Box, BoxProps } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledIssueAccordion = styled(Accordion)<AccordionProps>(({ expanded }) => ({
  border: '1px solid #D9D9D9',
  '& .MuiAccordion-heading .MuiButtonBase-root': {
    backgroundColor: '#FFF9F0',
    borderRadius: '5px',
    borderBottom: expanded ? '1px solid #D9D9D9' : 'none',
    borderBottomRightRadius: expanded ? 'inherit' : '5px',
    borderBottomLeftRadius: expanded ? 'inherit' : '5px',
    minHeight: '50px',
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '50px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '&.MuiPaper-root': {
    boxShadow: expanded
      ? '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
      : 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(() => ({
  display: 'flex',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: '5px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginRight: '5px',
  },
  paddingLeft: '30px !important',
  paddingRight: '24px !important',
}));

export const StyledIssueAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(() => ({
  padding: '40px 30px',
}));

export const StyledAccordionInnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '50%',
  height: 20,
  width: 20,
  color: theme.colors.secondary.contrast, // secondary.contrast
  backgroundColor: theme.colors.secondary.main, // secondary.main
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledIssueLoadingButton = styled(LoadingButton)({
  padding: '6px 16px',
  width: 150,
});
