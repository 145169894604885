import { Dispatch, FC, memo, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledAnchoredPaper,
  StyledIconButton,
  StyledModalTitleBox,
  StyledPopper,
  StyledTextButton,
} from '@pages/Invoices/styled';
import { formatDate } from '@utils/formatTime';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Box, Button, Divider, Fade, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface SelectDatePopperProps {
  handleSelectDate: Dispatch<SetStateAction<string>>;
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handlePickDate: (date: string) => void;
}

const SelectDatePopper: FC<SelectDatePopperProps> = ({
  handleSelectDate,
  handleOpen,
  open,
  anchorEl,
  handlePickDate,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);

  const handleConfirmClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (date) {
      handleSelectDate(formatDate(date));
      handlePickDate(formatDate(date));
    }
    handleOpen(e);
    setDate(null);
  };

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    handleOpen(event);
    setDate(null);
  };

  const handleChangeDate = (newDate: Date | null) => {
    setDate(newDate);
  };

  return (
    <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={0}>
          <StyledAnchoredPaper elevation={6}>
            <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize={18} fontFamily="WFVisualSans">
                {t('dashboard.selectDate')}
              </Typography>

              <StyledIconButton disableRipple onClick={handleOpen} sx={{ '& svg': { color: '#5A1ED3' } }}>
                <CloseIcon />
              </StyledIconButton>
            </StyledModalTitleBox>

            <Box padding="24px 20px 30px">
              <Typography mb={4} fontWeight={400} fontSize={16}>
                {t('dashboard.selectDateJumpTo')}
              </Typography>

              <DatePicker
                value={date}
                onChange={handleChangeDate}
                label={t('dashboard.selectDate')}
                slots={{
                  openPickerIcon: EventIcon,
                }}
                slotProps={{
                  textField: () => ({
                    sx: {
                      '& .MuiInputBase-root': {
                        fontWeight: 400,
                        fontSize: '16px',
                      },
                      '& .MuiFormLabel-root': {
                        fontWeight: 400,
                        fontSize: '16px',
                      },
                      '&.MuiFormControl-root': { width: '100%' },
                    },
                    variant: 'outlined',
                    color: 'primary',
                  }),
                }}
              />
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" p="20px">
              <StyledTextButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                variant="text"
                onClick={handleCancel}
                sx={{ height: 36, p: '4px' }}
              >
                <Typography fontSize={14}>{t('common.cancel')}</Typography>
              </StyledTextButton>
              <Button
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={handleConfirmClick}
                sx={{ maxWidth: '52px', height: 36 }}
              >
                <Typography fontSize={14}>{t('common.go')}</Typography>
              </Button>
            </Box>
          </StyledAnchoredPaper>
        </Fade>
      )}
    </StyledPopper>
  );
};

export default memo(SelectDatePopper);
