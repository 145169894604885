import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledIconButton, StyledModal, StyledModalTitleBox, StyledTextButton } from '@pages/Invoices/styled';
import { formatDate } from '@utils/formatTime';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Box, Button, Divider, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const SelectDateModal = NiceModal.create(
  ({
    handleSelectDate,
    handlePickDate,
  }: {
    handleSelectDate: Dispatch<SetStateAction<string>>;
    handlePickDate: (date: string) => void;
  }) => {
    const [date, setDate] = useState<Date | null>();
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = async () => {
      if (date) {
        handleSelectDate(formatDate(date));
        handlePickDate(formatDate(date));
      }
      modal.resolve(true);
      modal.remove();
    };

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal disableRestoreFocus open={modal.visible} size="xs" onClose={handleCancelClick}>
        <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t('dashboard.selectDate')}
          </Typography>

          <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
            <CloseIcon />
          </StyledIconButton>
        </StyledModalTitleBox>

        <Box padding="24px 20px 30px">
          <Typography mb={4} fontWeight={400} fontSize={16}>
            {t('dashboard.selectDateJumpTo')}
          </Typography>

          <DatePicker
            value={date}
            onChange={newDate => setDate(newDate)}
            label={t('dashboard.selectDate')}
            slots={{
              openPickerIcon: EventIcon,
            }}
            slotProps={{
              textField: () => ({
                sx: {
                  '& .MuiInputBase-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '& .MuiFormLabel-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '&.MuiFormControl-root': { width: '100%' },
                },
                variant: 'outlined',
                color: 'primary',
              }),
            }}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p="20px">
          <StyledTextButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            variant="text"
            onClick={handleCancelClick}
            sx={{ height: 36, p: '4px' }}
          >
            <Typography fontSize={14}>{t('common.cancel')}</Typography>
          </StyledTextButton>
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleConfirmClick}
            sx={{ maxWidth: '52px', height: 36 }}
          >
            <Typography fontSize={14}>{t('common.go')}</Typography>
          </Button>
        </Box>
      </StyledModal>
    );
  },
);

export const SelectDateModalId = 'SelectDateModal';

NiceModal.register(SelectDateModalId, SelectDateModal);
