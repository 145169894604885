import { FC, memo, MouseEvent } from 'react';
import { SortBy } from '@pages/Tasks/enums';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RemoveIcon from '@mui/icons-material/Remove';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface SortingGroupProps {
  sortValue: SortBy;
  handleChange: (e: MouseEvent<HTMLElement>, sort: SortBy) => void;
}

const SortingGroup: FC<SortingGroupProps> = ({ sortValue, handleChange }) => (
  <ToggleButtonGroup size="medium" value={sortValue} exclusive onChange={handleChange} color="primary">
    <ToggleButton value={SortBy.NONE}>
      <RemoveIcon />
    </ToggleButton>
    <ToggleButton value={SortBy.DESC}>
      <SortByAlphaIcon />
    </ToggleButton>
    <ToggleButton value={SortBy.DATE}>
      <CalendarTodayIcon />
    </ToggleButton>
  </ToggleButtonGroup>
);

export default memo(SortingGroup);
