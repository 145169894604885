import { DAYS_PER_WEEK } from '@constants/common';
import { ICONS } from '@pages/Invoices/enums';
import { CalendarCell } from '@pages/Invoices/types';

import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  ButtonProps,
  Chip,
  ChipProps,
  Dialog,
  DialogProps,
  IconButtonProps,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  SpeedDial,
  SpeedDialProps,
  Tabs,
  TabsProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import LoadingButton from '@components/LoadingButton';

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }
  #image-renderer {
    background-image: none;
  }
`;

export const StyledControlsBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.background.secondaryBackground,
  height: '60px',
}));

export const StyledFiltersButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
});

export const StyledTextField = styled(TextField)<TextFieldProps>({
  margin: '10px 0',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(43px, 17px)',
  },
});

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { theme?: Theme }>(() => ({
  '& .MuiDataGrid-footerContainer': {
    border: 'transparent',
    justifyContent: 'start',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
}));

export const StyledClaimTypography = styled(Typography)<TypographyProps & { theme?: Theme; claimed?: boolean }>(
  ({ theme, claimed = false }) => ({
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '2px',
    color: claimed ? theme.colors.primary.main : theme.colors.warning.main,
  }),
);

export const StyledLoadingButton = styled(LoadingButton)<{ theme?: Theme }>(({ theme }) => ({
  '& .MuiCircularProgress-root': {
    color: theme.colors.primary.main,
  },
  boxShadow: 'none',
}));

export const StyledViewTabs = styled(Tabs)<TabsProps & { downSm?: boolean }>(({ downSm }) => ({
  display: 'flex',
  '& .MuiButtonBase-root': {
    fontWeight: 500,
    fontSize: 14,
    padding: downSm ? '0 12px' : '0 16px',
    minHeight: '42px',
    flexGrow: downSm ? '1' : '0',
  },
  '& .MuiTabs-indicator': {
    bottom: '6px',
  },
  width: '100%',
  justifyContent: downSm ? 'center' : 'start',
}));

export const StyledTextButton = styled(Button)<ButtonProps>(() => ({
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-root.Mui-focusVisible': {
    boxShadow: 'none',
  },
}));

export const StyledNoShadowButton = styled(Button)<ButtonProps>(() => ({
  boxShadow: 'none',
}));

const mapSizes = {
  xs: {
    '.MuiPaper-root': {
      maxWidth: '358px',
      width: '100%',
    },
  },
  small: {
    '.MuiPaper-root': {
      maxWidth: '500px',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'xs' | 'small'; theme?: Theme }>(
  ({ size, theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.colors.white,
      margin: '8px',
    },
    zIndex: 2000,
    ...(size && mapSizes[size]),
  }),
);

export const StyledPopper = styled(Popper)<PopperProps>({
  zIndex: 2500,
});

export const StyledAnchoredPaper = styled(Paper)<PaperProps>({
  backgroundColor: 'white',
  width: '358px',
  marginTop: '8px',
});

export const StyledCenteredIconButton = styled(IconButton)({
  alignSelf: 'center',
});

export const StyledOkBtn = styled(Button)({
  maxWidth: '51px',
});

export const StyledButtonGroup = styled(ButtonGroup)({
  '& .MuiButtonBase-root': { padding: '7px 16px' },
});

export const StyledAutocompleteParticipantTextInputField = styled(TextField)({
  '& .MuiInputBase-root': {
    gap: '12px',
  },
  '& .MuiFormLabel-root': {
    fontWeight: 400,
  },
});

export const StyledSpeedDial = styled(SpeedDial)<SpeedDialProps>(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 16,
  zIndex: 2500,
  alignItems: 'end',
  '& .MuiSpeedDial-actions': {
    marginLeft: '-50px',
    '& .MuiButtonBase-root': {
      color: theme.colors.contrast,
      height: '40px',
      width: 'fit-content',
      alignSelf: 'flex-end',
      padding: '8px 16px',
      borderRadius: '100px',
      marginRight: 0,
      '& .MuiBox-root': {
        alignItems: 'center',
      },
    },
  },
}));

export const StyledMobileInvoiceRow = styled(Box)<
  BoxProps & {
    isLast: boolean;
    isSelected: boolean;
  }
>(({ theme, isLast, isSelected }) => ({
  color: theme.colors.secondary.contrast,
  padding: '9px',
  borderRadius: '4px',
  border: `1px solid ${isSelected ? theme.colors.primary.main : isLast ? '#BA9370' : '#153A60'}`,
  outline: isSelected ? `2px solid ${theme.colors.primary.main} !important` : 'none',
  '&:hover': {
    border: `1px solid ${isLast ? '#BA9370' : '#153A60'}`,
    outline: `2px solid ${isLast ? '#BA9370' : '#153A60'} !important`,
  },
  backgroundColor: isLast ? '#FFF7DC' : '#EEFDFF',
  '& svg': {
    color: isLast ? '#BA9370' : '#153A60',
  },
}));

export const StyledTruncatedTypographyContrast = styled(Typography)<TypographyProps & { linesLimit?: number }>(
  ({ theme, linesLimit }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: linesLimit,
    color: theme.colors.secondary.contrast,
  }),
);

export const StyledActionRequiredBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#FFF9F0',
  borderRadius: '3px',
  border: '1px solid #5F2120',
}));

export const StyledYellowCountBadge = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '50%',
  height: '20px',
  width: '20px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.secondary.main,
  '& .action-count': {
    color: theme.colors.secondary.contrast,
  },
}));

export const StyledChipDayOfService = styled(Chip)<ChipProps>(({ theme }) => ({
  '& .MuiChip-label': {
    color: theme.colors.secondary.contrast,
    fontWeight: 400,
    fontSize: '13px',
  },
}));

export const StyledSelectedDesktopDayOfWeekCircle = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.primary.main,
  color: theme.colors.white,
  height: 26,
  width: 26,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '16px',
}));

export const StyledSemiGreyTypography = styled(Typography)<TypographyProps>({
  color: '#0000008F',
});

export const StyledCellContainer = styled(Box)<
  BoxProps & { index: number; cell: CalendarCell; isSelected: boolean; isFirstCellOfLastRow: boolean; length: number }
>(({ theme, index, cell, isSelected, isFirstCellOfLastRow, length }) => {
  const isLeftBorderIndex = index % DAYS_PER_WEEK === 0;
  const isRightBorderIndex = index % DAYS_PER_WEEK === DAYS_PER_WEEK - 1 || index === length - 1;
  const isBottomBorderIndex = length - DAYS_PER_WEEK <= index;

  return {
    backgroundColor:
      index < DAYS_PER_WEEK && cell.dayOfMonth >= 25 && cell.dayOfMonth <= 31 ? '#EEEEEE' : theme.colors.white,
    height: 170,
    padding: isSelected ? '6px 8.5px 11px' : '7px 10px 12px',
    border: `${isSelected ? '2px' : '0.5px'} solid ${isSelected ? theme.colors.primary.main : '#0000003B'}`,
    borderTopLeftRadius: index === 0 ? '5px' : 0,
    borderTopRightRadius: index === DAYS_PER_WEEK - 1 ? '5px' : 0,
    borderBottomLeftRadius: isFirstCellOfLastRow && index !== 0 ? '5px' : 0,
    overflowWrap: 'anywhere', // contain invoice row to be within container
    borderTopWidth: isSelected ? '2px' : index < DAYS_PER_WEEK ? '1px' : '0.5px',
    borderLeftWidth: isSelected ? '2px' : isLeftBorderIndex ? '1px' : '0.5px',
    borderRightWidth: isSelected ? '2px' : isRightBorderIndex ? '1px' : '0.5px',
    borderBottomWidth: isSelected ? '2px' : isBottomBorderIndex ? '1px' : '0.5px',
  };
});

export const StyledDesktopInvoiceRow = styled(Box)<
  BoxProps & {
    role?: ICONS;
  }
>(({ theme, role }) => ({
  color: theme.colors.secondary.contrast,
  padding: '7px',
  borderRadius: '4px',
  border: `1px solid ${role === ICONS.CIRCLE ? '#BA9370' : '#153A60'}`,
  '&:hover': {
    border: `1px solid ${role === ICONS.CIRCLE ? '#BA9370' : '#153A60'}`,
    outline: `2px solid ${role === ICONS.CIRCLE ? '#BA9370' : '#153A60'} !important`,
  },
  backgroundColor: role === ICONS.CIRCLE ? '#FFF7DC' : '#EEFDFF',
  '& svg': {
    color: role === ICONS.CIRCLE ? '#BA9370' : '#153A60',
  },
}));

export const StyledViewDetailsBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.background.detailsBackground,
  color: theme.colors.primary.main,
}));

export const StyledDetailsBgBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#FAFAFA',
}));

export const StyledErrorDueDateChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    fontSize: '13px',
    fontWeight: 400,
    height: '24px',
    border: `1px solid ${theme.colors.error.main} !important`,
  },
}));

export const StyledChip = styled(Chip)<ChipProps & { isDark?: boolean }>(({ theme, isDark, color }) => ({
  '&.MuiChip-root': {
    height: '24px',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    backgroundColor: isDark ? theme.colors.primary.dark : 'inherit',
    borderColor: color === 'info' ? theme.colors.info.dark : 'inherit',
    color: color === 'info' ? theme.colors.info.dark : 'auto',
  },
}));

export const StyledSecondaryChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    height: '24px',
    fontSize: '13px',
    fontWeight: 400,
    color: theme.colors.secondary.contrast,
    fontFamily: 'Poppins',
  },
}));

export const StyledOutlinedDarkChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-root': {
    height: '24px',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderColor: `${theme.colors.primary.dark} !important`,
    color: `${theme.colors.primary.dark} !important`,
    background: theme.colors.white,
  },
}));

export const StyledModalTitleBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: theme.colors.background.detailsBackground,
  padding: '15px 20px',
  color: '#000000DE',
}));

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: '2px',
});
