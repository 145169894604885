import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { StyledNotificationTypography } from '@pages/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal, StyledModalNotificationTitleBox } from '../styled';

const UndoModal = NiceModal.create(
  ({
    title,
    text,
    buttonText,
    cancelButtonText,
    modalTitle,
  }: {
    title: string;
    text: string;
    buttonText: string;
    modalTitle: string;
    cancelButtonText?: string;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const { downSm } = useBreakpoints();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
        <StyledModalNotificationTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <StyledNotificationTypography fontFamily="WFVisualSans" fontSize={18}>
            {t(modalTitle)}
          </StyledNotificationTypography>

          <Box>
            <IconButton onClick={handleCancelClick} sx={{ padding: '2px' }}>
              <CloseIcon sx={{ '& svg': { color: '#663C00' } }} />
            </IconButton>
          </Box>
        </StyledModalNotificationTitleBox>

        <Box padding="24px 20px 30px" borderBottom="1px solid #0000001F">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t(title)}
          </Typography>

          <Typography fontWeight={400} mt={2}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
          <StyledTextButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="text"
            color="primary"
            onClick={handleConfirmClick}
          >
            <Typography fontSize={14}>{buttonText}</Typography>
          </StyledTextButton>
          <Button
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            color="primary"
            onClick={handleCancelClick}
            sx={{ height: 36, width: 84, boxShadow: 2 }}
          >
            <Typography fontSize={14}>{cancelButtonText}</Typography>
          </Button>
        </Box>
      </StyledModal>
    );
  },
);

export const UndoModalId = 'UndoModal';

NiceModal.register(UndoModalId, UndoModal);
