import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { TaskView } from '@pages/Tasks/enums';
import { StyledDeleteEventTitleBox, StyledIconButton, StyledModal } from '@pages/Tasks/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

interface RemoveFromListModalProps {
  view: TaskView;
  deleteMutation: () => Promise<void>;
}

const RemoveFromListModal = NiceModal.create(({ view, deleteMutation }: RemoveFromListModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { downSm } = useBreakpoints();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmClick = async () => {
    setIsLoading(true);

    await deleteMutation();
    modal.resolve(true);
    setIsLoading(false);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  const listName = view === TaskView.CLAIM ? t('tasks.list.claim') : t('tasks.list.paid');

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <StyledDeleteEventTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {t('tasks.modal.removeFromList.title', { listName })}
        </Typography>

        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#663C00' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDeleteEventTitleBox>
      <Box padding="24px 20px 30px">
        <Typography>
          <Trans i18nKey={t('tasks.modal.removeFromList.subtitle')} values={{ listName }} />
        </Typography>

        <Typography fontWeight={400} mt={2}>
          <Trans
            i18nKey={t('tasks.modal.removeFromList.text')}
            values={{ state: view === TaskView.CLAIM ? t('tasks.state.claimed') : t('tasks.state.paid') }}
          />
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
        <StyledTextButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          color="primary"
          onClick={handleCancelClick}
          sx={{ height: 36 }}
        >
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>
        <LoadingButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={handleConfirmClick}
          sx={{ height: 36, maxWidth: 98, boxShadow: 2 }}
        >
          <Typography fontSize={14}>{t('common.continue')}</Typography>
        </LoadingButton>
      </Box>
    </StyledModal>
  );
});

export const RemoveFromListModalId = 'RemoveFromListModal';

NiceModal.register(RemoveFromListModalId, RemoveFromListModal);
