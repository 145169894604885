import { FC, Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { useMenuActions } from '@hooks/useMenuActions';
import { usePathname } from '@hooks/usePathname';
import { StyledUploadIcon } from '@providers/layouts/AppLayout/styled';
import { UploadFileModalId } from '@shared/UploadFileModal';

import FileOpenTwoToneIcon from '@mui/icons-material/FileOpenTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Divider, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const MobilePrimaryNavigation: FC = () => {
  const pathname = usePathname();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { anchorEl, open, handleOpen, handleClose } = useMenuActions();

  const menuConfig = useMemo(
    () => [
      {
        icon: HomeTwoToneIcon,
        label: t('dashboard.mobileNav.home'),
        path: ROUTING.ROOT,
        exact: true,
      },
      {
        icon: FileOpenTwoToneIcon,
        label: t('dashboard.mobileNav.invoices'),
        path: ROUTING.INVOICES,
        pattern: /^\/invoices(?:\/\d+)?$/,
      },
      {
        icon: TaskTwoToneIcon,
        label: t('dashboard.mobileNav.tasks'),
        path: ROUTING.TASKS,
        pattern: /^\/tasks(?:\?.*)?$/,
      },
    ],
    [t],
  );

  const currentPath = useMemo(() => {
    const currentMenuItem = menuConfig.find(item => {
      if (item.exact) {
        return pathname === ROUTING.ROOT;
      }
      if (item.pattern) {
        return item.pattern.test(pathname);
      }
      return pathname.startsWith(`/${item.path}`);
    });

    return currentMenuItem?.label || '';
  }, [menuConfig, pathname]);

  const handleNavigate = (path: string) => {
    navigate(path === ROUTING.ROOT ? ROUTING.ROOT : `/${path}`);
    handleClose();
  };

  const handleOpenUploadModal = () => NiceModal.show(UploadFileModalId);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" flexGrow={1}>
      <IconButton onClick={handleOpen} color="primary">
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 8,
            sx: {
              overflow: 'auto',
              width: '200px',
              mt: 1.5,
              '& .MuiList-root': {
                py: 0,
                '& .MuiDivider-root': {
                  m: 0,
                },
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {menuConfig.map(({ icon: Icon, label, path }, index) => (
          <Fragment key={label}>
            <MenuItem onClick={() => handleNavigate(path)}>
              <Box display="flex" alignItems="center" gap="12px" py="15px">
                <Icon color="primary" />
                <Typography fontWeight={400}>{label}</Typography>
              </Box>
            </MenuItem>
            {index < menuConfig.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Menu>

      <Typography fontFamily="WFVisualSans" fontSize={18}>
        {currentPath}
      </Typography>

      <StyledUploadIcon color="primary" onClick={handleOpenUploadModal}>
        <UploadFileIcon sx={{ '& svg': { color: '#5A1ED3' } }} />
      </StyledUploadIcon>
    </Box>
  );
};

export default memo(MobilePrimaryNavigation);
