import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledDeleteEventTitleBox, StyledIconButton } from '@pages/InvoiceDetails/modals/styled';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';

import { StyledDeleteModalButton, StyledModal } from '../styled';

const DeleteNoteModal = NiceModal.create(
  ({
    title,
    text,
    buttonText,
    cancelButtonText,
  }: {
    title: string;
    text: string;
    buttonText: string;
    cancelButtonText?: string;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    const { downSm } = useBreakpoints();

    return (
      <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
        <StyledDeleteEventTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t(title)}
          </Typography>
          <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#663C00' } }}>
            <CloseIcon />
          </StyledIconButton>
        </StyledDeleteEventTitleBox>

        <Box p="24px 20px 30px">
          <Typography fontWeight={400}>{t(text)}</Typography>
        </Box>

        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
          <StyledTextButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="text"
            onClick={handleCancelClick}
            sx={{ height: 36 }}
          >
            {cancelButtonText}
          </StyledTextButton>
          <StyledDeleteModalButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            color="primary"
            onClick={handleConfirmClick}
            sx={{ height: 36 }}
          >
            {buttonText}
          </StyledDeleteModalButton>
        </Box>
      </StyledModal>
    );
  },
);

export const DeleteNoteModalId = 'DeleteNoteModal';

NiceModal.register(DeleteNoteModalId, DeleteNoteModal);
