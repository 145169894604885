import ErrorIcon from '@mui/icons-material/Error';
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  DividerProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledEditSectionButton = styled(Button)<ButtonProps>({
  maxWidth: '200px',
  padding: '5px',
  fontWeight: 500,
  fontSize: '13px',
  height: '30px',
});

export const StyledTitle = styled(Typography)<TypographyProps & { hasError?: boolean; theme?: Theme }>(
  ({ hasError, theme }) => ({
    fontWeight: 400,
    color: hasError ? theme.colors.error.main : theme.colors.black,
  }),
);

export const StyledAlertAutocomplete = styled(Autocomplete)(() => ({
  borderRadius: '5px',
  flex: 1,
  '& .MuiInputBase-root': {
    fontWeight: 400,
  },
}));

export const StyledEditTextFieldMaxWidth = styled(TextField)<TextFieldProps>({
  maxWidth: '600px',
});

export const StyledAmountTextField = styled(TextField)<TextFieldProps>(({ theme, error }) => ({
  maxWidth: '300px',
  '& .MuiInputAdornment-root': {
    marginRight: 0,
  },
  '& .MuiInputBase-root': {
    color: error ? theme.colors.error.main : theme.colors.black,
  },
}));

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  position: 'relative',
  '& .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 400,
    position: 'absolute',
    left: '40px',
    textWrap: 'nowrap',
  },
});

export const StyledSwitchFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>({
  '& .MuiTypography-root': {
    fontWeight: 400,
    fontSize: '14px',
  },
});

export const StyledSwitch = styled(Switch)<SwitchProps>({
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  },
});

export const StyledCancelButton = styled(Button)<ButtonProps>({
  padding: '6px 16px',
  boxShadow: 'none',
});

export const StyledAlertTextField = styled(TextField)<TextFieldProps>(({ error, theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    height: '50px',
    fontWeight: 400,
    color: error ? theme.colors.error.main : theme.colors.black,
  },
  flexGrow: 1,
}));

export const StyledDetailsBox = styled(Box)<BoxProps & { isFileName: boolean; isActionRequired?: boolean }>(
  ({ theme, isFileName, isActionRequired }) => ({
    backgroundColor: isActionRequired ? '#FFF' : isFileName ? '#F9F4FF' : '#FAF9F9',
    borderRadius: '3px',
    border: `1px solid ${isActionRequired ? '#D9D9D9' : isFileName ? theme.colors.primary.dark : '#D9D9D9'}`,
    padding: '10px 20px',
  }),
);

export const StyledDetailTypography = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

export const StyledIssueLoadingButton = styled(LoadingButton)({
  width: '150px',
  padding: '5px 10px',
});

export const StyledEditInvoiceAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
  '& .MuiFormLabel-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export const StyledEditInvoiceTextField = styled(TextField)<TextFieldProps>(({ error, theme }) => ({
  '& .MuiInputBase-root': {
    fontWeight: 400,
    fontSize: '16px',
    color: error ? theme.colors.error.main : theme.colors.black,
  },
  '& .MuiFormLabel-root': {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export const StyledSelect = styled(Select)<SelectProps & { error?: boolean }>(({ error, theme }) => ({
  color: error ? theme.colors.error.main : 'inherit',
  '& .MuiSelect-select.MuiInputBase-input': {
    fontWeight: 400,
    color: error ? theme.colors.error.main : 'inherit',
  },
  '& .MuiSelect-select': {
    color: error ? theme.colors.error.main : 'inherit',
  },
}));

export const StyledInputLabel = styled(InputLabel)<InputLabelProps>({
  fontWeight: 400,
});

export const StyledFormControl = styled(FormControl)<FormControlProps>(({ disabled }) => ({
  '& .MuiSvgIcon-root': {
    display: disabled ? 'none' : 'inline-block',
  },
}));

export const StyledErrorIcon = styled(ErrorIcon)({
  '&.MuiSvgIcon-root': {
    color: '#733C3A',
  },
});

export const StyledDivider = styled(Divider)<DividerProps>({
  height: '1px',
});
