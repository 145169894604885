import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { NOT_AVAILABLE } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledEditInvoiceTextField } from '@pages/InvoiceDetails/components/DetailsTab/styled';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { TaskView } from '@pages/Tasks/enums';
import { AddNoteModalId } from '@pages/Tasks/modals/AddNoteModal';
import {
  StyledBottomBox,
  StyledCardBgBox,
  StyledCardPaper,
  StyledFormControlBox,
  StyledTruncatedTypographyContrast,
} from '@pages/Tasks/styled';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NotesIcon from '@mui/icons-material/Notes';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';

import { StyledTypographyContrast } from '@/shared/styles';

interface InvoiceCardEditDetailsProps {
  invoice?: InvoiceRead;
  idx: number;
  view: TaskView;
}

const InvoiceCardEditDetails: FC<InvoiceCardEditDetailsProps> = ({ invoice, idx, view }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();

  const { control } = useFormContext();
  const theme = useTheme();

  if (!invoice) {
    return null;
  }

  const supportDate =
    !invoice.service_start_date && !invoice.service_end_date && invoice.service_exact_date
      ? convertIsoToReadable(invoice.service_exact_date, { fullYear: true })
      : invoice.service_start_date &&
        invoice.service_end_date &&
        `${convertIsoToReadable(invoice.service_start_date, { fullYear: true })} - ${convertIsoToReadable(invoice.service_end_date, { fullYear: true })}`;

  const isClaimTab = view === TaskView.CLAIM;

  return (
    <StyledCardPaper
      elevation={2}
      sx={{
        maxWidth: {
          xs: '100%',
          sm: '502px'
        }
      }}
    >
      <StyledCardBgBox p="7px 0px 7px 15px" display="flex" alignItems="center">
        {/* add a note */}
        <StyledTextButton
          disableRipple
          endIcon={<NotesIcon />}
          color="primary"
          maxWidth="150px"
          style={{ boxShadow: 'none' }}
          variant="text"
          onClick={() => {
            NiceModal.show(AddNoteModalId, {
              invoiceId: invoice?.id,
              view,
            });
          }}
        >
          <Typography fontSize={13} alignSelf="end" mb="-1px">
            {t('tasks.addNote')}
          </Typography>
        </StyledTextButton>
      </StyledCardBgBox>
      <Box padding="16px 15px 20px" width="100%">
        <Box display="flex" gap={1} alignItems="center">
          <CalendarTodayIcon color="action" />
          <StyledTypographyContrast fontSize={16} fontWeight={400}>
            {supportDate || NOT_AVAILABLE}
          </StyledTypographyContrast>
        </Box>

        <Box mt="13px" display="flex" gap={1} alignItems="center">
          <BusinessIcon color="action" />
          <StyledTruncatedTypographyContrast fontSize={18} fontFamily="WFVisualSans" linesLimit={1}>
            {invoice.vendor_name}
          </StyledTruncatedTypographyContrast>
        </Box>

        <Box mt="14px" display="flex" gap={1} alignItems="center" justifyContent="space-between">
          <StyledTruncatedTypographyContrast fontSize={16} fontWeight={400} linesLimit={1}>
            {invoice.description}
          </StyledTruncatedTypographyContrast>
          <StyledTypographyContrast fontFamily="WFVisualSans" fontSize={22}>
            {`${invoice.currency_symbol ? invoice.currency_symbol : ''}${formatCurrency(invoice.total_amount)}`}
          </StyledTypographyContrast>
        </Box>
      </Box>

      <StyledBottomBox p="10px 17px" display="flex" gap="10px" width="100%" flexDirection={downSm ? 'column' : 'row'}>
        {/* two controllers */}
        <Controller
          control={control}
          name={`data.${idx}.${isClaimTab ? 'claimNumber' : 'payNumber'}`}
          render={({ field, fieldState }) => (
            <StyledFormControlBox
              id={`data.${idx}.${isClaimTab ? 'claimNumber' : 'payNumber'}`}
              flexGrow={1}
              width="100%"
            >
              <StyledEditInvoiceTextField
                fullWidth
                variant="outlined"
                label={isClaimTab ? t('tasks.claim.claimId') : t('tasks.pay.payNumber')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': `${isClaimTab ? 'claimId' : 'payment reference'} input field`,
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            </StyledFormControlBox>
          )}
        />

        <Controller
          control={control}
          name={`data.${idx}.${isClaimTab ? 'claimDate' : 'payDate'}`}
          render={({ field, fieldState }) => (
            <StyledFormControlBox id={`data.${idx}.${isClaimTab ? 'claimDate' : 'payDate'}`} flexGrow={1} width="100%">
              <DatePicker
                {...field}
                label={isClaimTab ? t('tasks.claim.claimDate') : t('tasks.pay.payDate')}
                slots={{
                  openPickerIcon: CalendarTodayIcon,
                }}
                slotProps={{
                  textField: () => ({
                    sx: {
                      fontWeight: 400,
                      '& .MuiInputBase-root': {
                        fontWeight: 400,
                        color: fieldState.error ? theme.colors.error.main : theme.colors.black,
                      },
                      '& .MuiFormLabel-root': {
                        fontWeight: 400,
                        fontSize: '16px',
                      },
                    },
                    variant: 'outlined',
                    error: Boolean(fieldState.error),
                    helperText:
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                  }),
                }}
              />
            </StyledFormControlBox>
          )}
        />
      </StyledBottomBox>
    </StyledCardPaper>
  );
};

export default memo(InvoiceCardEditDetails);
