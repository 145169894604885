import { SubscriptionPortalLink, useSubscriptionsPortalRetrieveQuery, useSubscriptionsRetrieveQuery } from '@api/api';

export const useSubscription = () => {
  const {
    data: subscriptionData,
    isLoading: isLoadingSubscription,
    error: subscriptionError,
  } = useSubscriptionsRetrieveQuery();
  const { data: portalData, isLoading: isLoadingPortal, error: portalError } = useSubscriptionsPortalRetrieveQuery();

  const isTrial = subscriptionData?.is_trial ?? false;
  const trialDaysLeft = subscriptionData?.trial_days_left ?? 0;
  const daysLeft = subscriptionData?.days_left ?? 0;
  const subscriptionStatus = subscriptionData?.status ?? '';
  const subscriptionType = subscriptionData?.subscription_type;
  const portalUrl = (portalData as unknown as SubscriptionPortalLink)?.url;
  const cardNumber = subscriptionData?.card_last4 ?? '';

  const isLoading = [isLoadingSubscription, isLoadingPortal].some(Boolean);

  return {
    isTrial,
    trialDaysLeft,
    daysLeft,
    isLoadingSubscription: isLoading,
    subscriptionStatus,
    subscriptionError,
    data: subscriptionData,
    subscriptionType,
    portalError,
    portalUrl,
    cardNumber,
  };
};
