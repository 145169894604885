import * as yup from 'yup';

export const editClaimSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      claimNumber: yup.string(),
      claimDate: yup.date().nullable(),
      id: yup.number(),
    }),
  ),
});

export const editPaySchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      payNumber: yup.string(),
      payDate: yup.date().nullable(),
      id: yup.number(),
    }),
  ),
});
