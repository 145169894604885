import { FC, memo, useState } from 'react';
import { StyledSpeedDial } from '@pages/Tasks/styled';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RemoveIcon from '@mui/icons-material/Remove';
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Box, SpeedDialAction } from '@mui/material';

interface SortSpeedDialProps {
  sortDefault: VoidFunction;
  sortByName: VoidFunction;
  sortByDate: VoidFunction;
}

const SortSpeedDial: FC<SortSpeedDialProps> = ({ sortDefault, sortByName, sortByDate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [
    {
      icon: <RemoveIcon />,
      name: '',
      onClick: () => {
        sortDefault();
        handleClose();
      },
      id: 1,
    },
    {
      icon: <SortByAlphaIcon />,
      name: '',
      onClick: () => {
        sortByName();
        handleClose();
      },
      id: 2,
    },
    {
      icon: <CalendarTodayIcon />,
      name: '',
      onClick: () => {
        sortByDate();
        handleClose();
      },
      id: 3,
    },
  ];

  return (
    <>
      <Box>
        <StyledSpeedDial
          ariaLabel="SpeedDial controlled"
          icon={<SortIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map(action => (
            <SpeedDialAction key={action.id} icon={action.icon} onClick={action.onClick} />
          ))}
        </StyledSpeedDial>
      </Box>
    </>
  );
};

export default memo(SortSpeedDial);
