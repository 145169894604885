import { FC, memo, PropsWithChildren } from 'react';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePathname } from '@hooks/usePathname';
import { useSubscription } from '@hooks/useSubscription';
import TrialBar from '@providers/layouts/AppLayout/components/TrialBar';
import { StyledAppLayoutWrapper } from '@providers/layouts/AppLayout/styled';

import Box from '@mui/material/Box';

import Main from './components/Main';
import Navigation from './components/Navigation';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isTrial, isLoadingSubscription, trialDaysLeft } = useSubscription();
  const pathname = usePathname();
  const isInvoiceDetailsPage = /^\/invoices\/\d+$/.test(pathname);
  const isSettingsPage = pathname.includes(ROUTING.SETTINGS);
  const { downMd } = useBreakpoints();

  if (isLoadingSubscription) return null;

  return (
    <StyledAppLayoutWrapper minHeight="100vh">
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        style={{
          position:
            isInvoiceDetailsPage && !downMd
              ? 'sticky'
              : isSettingsPage && !downMd
                ? 'sticky'
                : !isInvoiceDetailsPage
                  ? 'sticky'
                  : 'relative',
          top: 0,
          zIndex: 2000,
          backgroundColor: 'white',
        }}
      >
        <Navigation />
        {isTrial && !isInvoiceDetailsPage && <TrialBar daysLeft={trialDaysLeft} />}
      </Box>
      <Main>{children}</Main>
    </StyledAppLayoutWrapper>
  );
};

export default memo(AppLayout);
