import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesNotesCreateMutation } from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { TaskView } from '@pages/Tasks/enums';
import Editor from '@pages/Tasks/modals/AddNoteModal/Editor';
import { StyledApplyToAllTitleBox, StyledIconButton, StyledModal } from '@pages/Tasks/styled';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

interface AddNoteModalProps {
  view: TaskView;
  invoiceId: number;
}

const AddNoteModal = NiceModal.create(({ view, invoiceId }: AddNoteModalProps) => {
  const [text, setText] = useState<string>('');
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const modal = useModal();
  const { downSm } = useBreakpoints();
  const [createNoteMutation, { isLoading: isLoadingCreateNoteMutation }] = useApiInvoicesNotesCreateMutation();

  const handleChange = (html: string) => setText(html);

  const handleAddNote = async () => {
    try {
      const res = await createNoteMutation({
        invoiceId,
        invoiceNoteRequest: { text },
      }).unwrap();

      if (res.created_at) {
        snackbar.enqueueSnackbar(t('tasks.modal.addNote.created'), { variant: SUCCESS });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const handleConfirmClick = async () => {
    if (text) {
      await handleAddNote();
    }
    modal.resolve(true);
    modal.remove();
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  const type = view === TaskView.CLAIM ? t('tasks.type.claim') : t('tasks.type.payment');

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <StyledApplyToAllTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {t('tasks.modal.addNote.title')}
        </Typography>

        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledApplyToAllTitleBox>
      <Box padding="24px 20px 30px">
        <Typography fontWeight={400}>{t('tasks.modal.addNote.subtitle', { type })}</Typography>

        {/* Editor box */}
        <Box my={3}>
          <Editor text={text} handleChange={handleChange} />
        </Box>

        <Typography fontWeight={400} fontSize={14}>
          {t('tasks.modal.addNote.helpText', { type })}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
        <StyledTextButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          color="primary"
          onClick={handleCancelClick}
          sx={{ height: 36 }}
        >
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>
        <LoadingButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="contained"
          color="primary"
          loading={isLoadingCreateNoteMutation}
          disabled={!text}
          onClick={handleConfirmClick}
          sx={{ height: 36, maxWidth: 140, boxShadow: 2 }}
        >
          <Typography fontSize={14}>{t('common.save')}</Typography>
        </LoadingButton>
      </Box>
    </StyledModal>
  );
});

export const AddNoteModalId = 'AddNoteModal';

NiceModal.register(AddNoteModalId, AddNoteModal);
