import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentWidget from '@pages/InvoiceDetails/components/DetailsTab/components/DocumentWidget';
import { IconStatus } from '@pages/InvoiceDetails/enums';

import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography, Zoom } from '@mui/material';

import { StyledDetailsBox, StyledDetailTypography } from '../styled';

const iconMapping = {
  [IconStatus.PREFILLED]: <AutoAwesomeTwoToneIcon color="primary" fontSize="small" />,
  [IconStatus.INFO]: <InfoIcon color="primary" fontSize="small" />,
  [IconStatus.ACTION]: <></>,
  [IconStatus.NONE]: <></>,
  [IconStatus.DOCUMENT]: <DocumentWidget />,
  [IconStatus.MISSING_DOCUMENT]: <InfoIcon color="primary" fontSize="small" />,
};

const Detail: FC<{ title: string; value?: string | false | null; iconStatus: IconStatus; tooltipTitle?: string }> = ({
  title,
  value,
  iconStatus,
  tooltipTitle,
}) => {
  const { t } = useTranslation();
  return (
    <Box width="100%" p={0} m={0}>
      <Typography mb="12px" color="text.secondary" fontWeight={400}>
        {t(title)}
      </Typography>
      <StyledDetailsBox
        height={45}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="10px 20px"
        isFileName={iconStatus === IconStatus.DOCUMENT}
        isActionRequired={iconStatus === IconStatus.ACTION}
      >
        <StyledDetailTypography
          fontSize={16}
          fontWeight={400}
          color={
            iconStatus === IconStatus.INFO ||
            iconStatus === IconStatus.MISSING_DOCUMENT ||
            iconStatus === IconStatus.ACTION
              ? '#636363'
              : '#202020'
          }
        >
          {t(value as string) || t('common.noneFound')}
        </StyledDetailTypography>
        {tooltipTitle ? (
          <Tooltip title={t(tooltipTitle)} placement="top" arrow TransitionComponent={Zoom}>
            {iconMapping[iconStatus]}
          </Tooltip>
        ) : (
          iconMapping[iconStatus]
        )}
      </StyledDetailsBox>
    </Box>
  );
};

export default memo(Detail);
