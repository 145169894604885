import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApiInvoicesDestroyMutation } from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { useUser } from '@hooks/api/useUser';
import { useBreakpoints } from '@hooks/useBreakpoints';
import Detail from '@pages/InvoiceDetails/components/DetailsTab/components/Detail';
import { useInvoiceDetailsConfig } from '@pages/InvoiceDetails/components/DetailsTab/components/hooks/useInvoiceDetailsConfig';
import { StyledDivider } from '@pages/InvoiceDetails/components/DetailsTab/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { UndoModalId } from '@pages/InvoiceDetails/modals/UndoModal';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { formatABN } from '@pages/InvoiceDetails/utils';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, Grid2, Typography } from '@mui/material';

const InvoiceDetailsSection: FC = () => {
  const { data: userData } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { invoiceData, changesData, invoiceId } = useInvoiceDetailsNewContext();

  const { config: detailsConfig } = useInvoiceDetailsConfig(userData, invoiceData, changesData);
  const { downSm } = useBreakpoints();

  const [handleDestroyInvoice, { isLoading: isLoadingDestroyInvoice }] = useApiInvoicesDestroyMutation();

  const handleDeleteInvoice = async () => {
    try {
      const result = await NiceModal.show(UndoModalId, {
        title: t('dashboard.undoModal.deleteInvoice.title'),
        text: t('dashboard.undoModal.deleteInvoice.text'),
        buttonText: t('dashboard.undoModal.deleteInvoice.buttonText'),
        cancelButtonText: t('common.cancel'),
        modalTitle: t('dashboard.undoModal.deleteInvoice.modalTitle'),
      });

      if (result) {
        await handleDestroyInvoice({ id: +invoiceId });
        navigate(`/${ROUTING.INVOICES}`, { replace: true });
        snackbar.enqueueSnackbar(t('dashboard.invoiceDeleted'), { variant: SUCCESS });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  return (
    <>
      <Grid2 container rowSpacing={3} columnSpacing={2} sx={{ flexGrow: 1 }} width="100%">
        {detailsConfig.map(({ id, title, value, iconStatus, renderCondition, dimensions, tooltipTitle }) => {
          const isServiceExactDateField = id === 3; // SERVICE_EXACT_DATE_FIELD
          const isAbnField = id === 9; // ABN_FIELD

          const shouldBreakAfter = isServiceExactDateField || isAbnField;

          return (
            <Fragment key={id}>
              {renderCondition && (
                <Grid2 size={dimensions} gap={3}>
                  <Detail
                    title={title}
                    value={isAbnField ? formatABN(value as string) : value}
                    iconStatus={iconStatus}
                    tooltipTitle={tooltipTitle}
                  />
                </Grid2>
              )}

              {!downSm && shouldBreakAfter && (
                <Grid2
                  size={{ xs: 0, sm: 0, md: 9, lg: 0 }}
                  sx={{ display: isServiceExactDateField && renderCondition ? 'flex' : 'none' }}
                />
              )}
            </Fragment>
          );
        })}
      </Grid2>
      <Box mt="32px">
        <StyledDivider />
        <Box mt="25px" display="flex" flexDirection="row-reverse">
          {!isLoadingDestroyInvoice && (
            <StyledTextButton
              disableRipple
              disableElevation
              disableTouchRipple
              disableFocusRipple
              variant="text"
              onClick={handleDeleteInvoice}
              endIcon={<DeleteIcon color="primary" />}
              color="primary"
              sx={{ height: 35 }}
            >
              <Typography>{t('dashboard.invoiceDetails.deleteInvoice')}</Typography>
            </StyledTextButton>
          )}
          {/* ToDo possibly need to rethink disabling UI while delete in process */}
          {isLoadingDestroyInvoice && <CircularProgress size={30} color="primary" />}
        </Box>
      </Box>
    </>
  );
};

export default memo(InvoiceDetailsSection);
