import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useEmailsInvoiceCheckUniqueCreateMutation } from '@api/api';
import { EMAIL_DOMAIN } from '@constants/auth';
import { MAX_ID_LENGTH } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const CreateIdContext = createContext<ReturnType<typeof useCreateIdContextValue> | null>(null);

const schema = yup.object().shape({
  emailId: yup.string(),
});

const MAX_ATTEMPTS = 100;

const useCreateIdContextValue = () => {
  const [editId, setEditId] = useState(false);
  const [checkEmailMutation, { isLoading: isLoadingCheck }] = useEmailsInvoiceCheckUniqueCreateMutation();

  const { state } = useLocation();
  const myEmail = state?.email as string;

  const checkEmailAvailability = useCallback(
    async (email: string): Promise<boolean> => {
      try {
        const res = await checkEmailMutation({
          inputRequest: { email },
        });
        return res?.data?.is_available || false;
      } catch (error) {
        console.error('Error checking email availability:', error);
        return false;
      }
    },
    [checkEmailMutation],
  );

  const findAvailableEmail = useCallback(
    async (basePrefix: string): Promise<string> => {
      for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
        const currentPrefix = attempt === 0 ? basePrefix : `${basePrefix}${attempt}`;
        const emailToCheck = `${currentPrefix}${EMAIL_DOMAIN}`;
        // eslint-disable-next-line no-await-in-loop
        const isAvailable = await checkEmailAvailability(emailToCheck);

        if (isAvailable) {
          return currentPrefix;
        }
      }

      console.warn(`Exhausted attempts for email prefix: ${basePrefix}`);
      return basePrefix;
    },
    [checkEmailAvailability],
  );

  const baseEmailPrefix = myEmail ? myEmail.split('@')[0].substring(0, MAX_ID_LENGTH) : '';
  const [emailPrefix, setEmailPrefix] = useState(baseEmailPrefix);

  const createIdForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: { emailId: emailPrefix },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    if (baseEmailPrefix) {
      findAvailableEmail(baseEmailPrefix)
        .then((prefix) => {
          setEmailPrefix(prefix);
          createIdForm.reset({ emailId: prefix })
        })
        .catch(error => {
          console.error('Error finding available email:', error);
        });
    }
  }, [baseEmailPrefix, createIdForm, findAvailableEmail]);

  const toggleEdit = useCallback(() => setEditId(prev => !prev), []);
  const isLoadingInitialEmailCheck = [isLoadingCheck].some(Boolean);

  return {
    createIdForm,
    editId,
    toggleEdit,
    emailPrefix,
    isLoadingInitialEmailCheck,
  };
};

export const useCreateIdContext = () => {
  const context = useContext(CreateIdContext);
  if (!context) throw new Error('useCreateIdContext must be inside CreateIdProvider');
  return context;
};

export const CreateIdProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useCreateIdContextValue();
  return <CreateIdContext.Provider value={value}>{children}</CreateIdContext.Provider>;
};
