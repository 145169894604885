/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface UploadState {
  refetchInvoices: boolean;
}
const initialState: UploadState = {
  refetchInvoices: false,
};
const uploadSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    uploadInvoiceSuccess: state => {
      state.refetchInvoices = true;
    },
    resetUploadInvoice: state => {
      state.refetchInvoices = false;
    },
  },
});
export const { uploadInvoiceSuccess, resetUploadInvoice } = uploadSlice.actions;
export default uploadSlice.reducer;
