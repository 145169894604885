import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useApiInvoicesListQuery } from '@api/api';
import { SUCCESS } from '@constants/auth';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSubscription } from '@hooks/useSubscription';
import CalendarView from '@pages/Invoices/components/CalendarView';
import SpeedDialButton from '@pages/Invoices/components/CalendarView/SpeedDialButton';
import ControlsBar from '@pages/Invoices/components/ControlsBar';
import InvoicesTable from '@pages/Invoices/components/TableView/InvoicesTable';
import { UPLOAD_INVOICE_REFETCH_TIMEOUT, UPLOAD_SINGLE_REFETCH_TIMEOUT } from '@pages/Invoices/constants';
import { useInvoicesContext } from '@pages/Invoices/context';
import { VIEW } from '@pages/Invoices/enums';
import PreviewInvoice from '@pages/Invoices/modals/PreviewInvoice';
import { RootState } from '@store/store';
import { resetUploadInvoice } from '@store/uploadSlice';
import { getEndOfMonth, getStartOfMonth } from '@utils/formatTime';
import { closeSnackbar, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GridPaginationModel } from '@mui/x-data-grid';

const action = (snackbarId: string | number) => (
  <>
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      sx={{ '& svg': { color: '#FFF' } }}
    >
      <CloseIcon />
    </IconButton>
  </>
);

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { t } = useTranslation();
  const { isLoadingSubscription } = useSubscription();
  const {
    view,
    handleChangeView,
    selectedMonth,
    isLoadingUserData,
    selectedParticipants,
    setSelectedMonth,
    handlePickDate,
  } = useInvoicesContext();
  const { downSm } = useBreakpoints();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const dispatch = useDispatch();
  const uploadState = useSelector((state: RootState) => state.upload);
  const snackbar = useSnackbar();

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
    refetch,
  } = useApiInvoicesListQuery({
    ...(view === VIEW.TABLE && { limit, offset: page * limit }),
    ...(view === VIEW.CALENDAR && {
      orderBy: 'created_at',
      order: 'asc',
    }),
    ...(view === VIEW.CALENDAR && {
      serviceStartDate: getStartOfMonth(selectedMonth),
      serviceEndDate: getEndOfMonth(selectedMonth),
    }),
    ...(view === VIEW.CALENDAR &&
      selectedParticipants.length > 0 && {
        participants: selectedParticipants
          .map(participant => `${participant.first_name} ${participant.last_name}`)
          .join(','),
      }),
  });

  const isLoading = [isLoadingInvoices, isFetching, isLoadingSubscription, isLoadingUserData].some(Boolean);

  useEffect(() => {
    let refetchIntervalId: NodeJS.Timeout;
    let cleanupTimeoutId: NodeJS.Timeout;
    let refetchCount = 0;
    let initialShow = true;

    if (uploadState.refetchInvoices) {
      if (initialShow) {
        snackbar.enqueueSnackbar(t('dashboard.fileUploadedInvoicesPageSnackbarText'), {
          variant: SUCCESS,
          action,
          persist: true,
          preventDuplicate: true,
        });
        initialShow = false;
      }
      refetch();
      // eslint-disable-next-line no-plusplus
      refetchCount++;

      refetchIntervalId = setInterval(() => {
        if (refetchCount < UPLOAD_INVOICE_REFETCH_TIMEOUT / UPLOAD_SINGLE_REFETCH_TIMEOUT + 1) {
          refetch();
          // eslint-disable-next-line no-plusplus
          refetchCount++;
        } else {
          clearInterval(refetchIntervalId);
        }
      }, UPLOAD_SINGLE_REFETCH_TIMEOUT);

      cleanupTimeoutId = setTimeout(() => {
        clearInterval(refetchIntervalId);
        dispatch(resetUploadInvoice());
      }, UPLOAD_INVOICE_REFETCH_TIMEOUT);
    }

    return () => {
      if (refetchIntervalId) {
        clearInterval(refetchIntervalId);
      }
      if (cleanupTimeoutId) {
        clearTimeout(cleanupTimeoutId);
      }
    };
  }, [uploadState.refetchInvoices, dispatch, refetch, snackbar, t]);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoicesReceipts')}</title>
      </Helmet>

      <ControlsBar view={view} onChange={handleChangeView} />
      <Box aria-label="invoices page" display="flex" flexDirection="column" height="100%">
        <Box height="100%">
          {view === VIEW.TABLE && (
            <InvoicesTable
              invoices={invoicesData}
              isLoading={isLoading}
              handlePageChange={handlePaginationModelChange}
              paginationModel={{ page, pageSize: limit }}
            />
          )}
          {view === VIEW.CALENDAR && <CalendarView invoicesData={invoicesData} isLoading={isLoading} />}
        </Box>
        <PreviewInvoice />
        {view === VIEW.CALENDAR && downSm && (
          <SpeedDialButton handleSetMonth={setSelectedMonth} handlePickDate={handlePickDate} />
        )}
      </Box>
    </>
  );
};

export default memo(Invoices);
