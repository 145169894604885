import { Box, BoxProps, Button, ButtonProps, IconButtonProps, Typography, TypographyProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledUploadBox = styled(Box)<BoxProps & { theme?: Theme }>(() => ({
  border: '2px dashed #E0E0E0',
  background: 'linear-gradient(#F9F9F9, #FFFFFF)',
  height: '254px',
  padding: '58px 0px',
  margin: '0 auto',
}));

export const StyledFileItemBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderRadius: '5px',
  background: theme.colors.background.detailsBackground,
}));

export const StyledFileItemName = styled(Typography)<TypographyProps>({
  maxWidth: '100%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

export const StyledAddFilesButton = styled(Button)<ButtonProps>({
  padding: '6px 16px',
  width: '120px',
  boxShadow: 'none',
  '& :hover': {
    boxShadow: 'none',
  },
  textWrap: 'nowrap',
  height: 36,
});

export const StyledInvoiceUploadTitleBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: theme.colors.background.detailsBackground,
  padding: '15px 20px',
}));

export const StyledIconButton = styled(IconButton)<IconButtonProps>({
  padding: '2px',
});

export const StyledFooterTextButton = styled(Button)<ButtonProps>(() => ({
  height: 36,
  boxShadow: 'none',
  '&:hover': { boxShadow: 'none' },
  padding: '8px',
}));
