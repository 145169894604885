import { useTranslation } from 'react-i18next';
import { NOT_AVAILABLE } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { getDueDaysLeft } from '@pages/InvoiceDetails/utils';
import {
  StyledChip,
  StyledDetailsBgBox,
  StyledErrorDueDateChip,
  StyledModal,
  StyledOutlinedDarkChip,
  StyledTruncatedTypographyContrast,
  StyledViewDetailsBox,
} from '@pages/Invoices/styled';
import { ExtendedInvoice } from '@pages/Invoices/types';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledTypographyContrast } from '@/shared/styles';

const QuickInfoModal = NiceModal.create(({ invoice }: { invoice: ExtendedInvoice }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleConfirmClick = async () => {
    window.location.assign(`/${ROUTING.INVOICES}/${invoice.id}`);
    modal.resolve(true);
    modal.remove();
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  const supportDate =
    !invoice.service_start_date && !invoice.service_end_date && invoice.support_date
      ? convertIsoToReadable(invoice.support_date, { fullYear: true })
      : invoice.service_start_date &&
        invoice.service_end_date &&
        `${convertIsoToReadable(invoice.service_start_date, { fullYear: true })} - ${convertIsoToReadable(invoice.service_end_date, { fullYear: true })}`;

  return (
    <StyledModal disableRestoreFocus open={modal.visible} size="xs" onClose={handleCancelClick}>
      <StyledDetailsBgBox p="7px 0px 7px 15px" display="flex" justifyContent="space-between" alignItems="center">
        {
          <Box display="flex" gap={1}>
            {/* SLOT_2 */}
            {invoice?.is_claimed ? (
              <StyledChip
                variant="filled"
                color="primary"
                isDark
                label={t('dashboard.invoiceDetails.claimed')}
                icon={<CheckIcon />}
              />
            ) : (
              <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notClaimed')} />
            )}

            {/* SLOT_3 */}
            {(() => {
              if (invoice.is_paid) {
                return (
                  <StyledChip
                    isDark
                    color="primary"
                    variant="filled"
                    label={t('dashboard.invoiceDetails.paid')}
                    icon={<CheckIcon />}
                  />
                );
              }
              if (!invoice.due_date) {
                return <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />;
              }

              if (invoice.due_date) {
                const dueDays = getDueDaysLeft(invoice.due_date);

                if (dueDays === 0) {
                  return (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueToday')}
                      color="error"
                      variant="outlined"
                    />
                  );
                }
                if (dueDays > 0 && dueDays <= 10) {
                  return (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueSoon')}
                      color="error"
                      variant="outlined"
                    />
                  );
                }

                if (dueDays > 0 && dueDays > 10) {
                  return <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />;
                }
                return (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.overdue')}
                    color="error"
                    variant="filled"
                  />
                );
              }
            })()}
          </Box>
        }

        <IconButton onClick={handleCancelClick}>
          <CloseIcon />
        </IconButton>
      </StyledDetailsBgBox>
      <Box padding="16px 15px 20px" width="100%">
        <Box display="flex" gap={1} alignItems="center">
          <CalendarTodayIcon color="action" />
          <StyledTypographyContrast fontSize={16} fontWeight={400}>
            {supportDate || NOT_AVAILABLE}
          </StyledTypographyContrast>
        </Box>

        <Box mt="13px" display="flex" gap={1} alignItems="center">
          <BusinessIcon color="action" />
          <StyledTruncatedTypographyContrast fontSize={18} fontFamily="WFVisualSans" linesLimit={1}>
            {invoice.vendor_name}
          </StyledTruncatedTypographyContrast>
        </Box>

        <Box mt="14px" display="flex" gap={1} alignItems="center" justifyContent="space-between">
          <StyledTruncatedTypographyContrast fontSize={16} fontWeight={400} linesLimit={1}>
            {invoice.description}
          </StyledTruncatedTypographyContrast>
          <StyledTypographyContrast fontFamily="WFVisualSans" fontSize={22}>
            {`${invoice.currency_symbol ? invoice.currency_symbol : ''}${formatCurrency(invoice.total_amount)}`}
          </StyledTypographyContrast>
        </Box>
      </Box>
      <StyledViewDetailsBox display="flex" width="100%">
        <Button
          fullWidth
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleConfirmClick}
          color="primary"
          variant="text"
          style={{ justifyContent: 'flex-start' }}
        >
          <Typography fontSize={14}>{t('dashboard.quickInfoModal.viewDetailsBtnText')}</Typography>
        </Button>
      </StyledViewDetailsBox>
    </StyledModal>
  );
});

export const QuickInfoModalId = 'QuickInfoModal';

NiceModal.register(QuickInfoModalId, QuickInfoModal);
