import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledChip, StyledOutlinedDarkChip, StyledSecondaryChip } from '@pages/InvoiceDetails/components/styled';
import { StyledErrorDueDateChip } from '@pages/InvoiceDetails/components/TasksTab/styled';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { getDueDaysLeft, truncateText } from '@pages/InvoiceDetails/utils';
import { formatCurrency } from '@utils/formatCurrency';

import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';

const DetailsHeader: FC<{ data?: InvoiceRead; actionRequired: boolean }> = ({ data, actionRequired }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { downMd, upLg, downSm } = useBreakpoints();
  const handleBack = () => navigate(-1)

  return (
    <Box
      m={0}
      p={0}
      style={{
        position: 'sticky',
        top: !downMd ? '78px' : '0px',
        zIndex: 2000,
        backgroundColor: 'white',
        boxShadow: '0px 3px 4px -1px #00000033, 0px 6px 4px 0px #00000024, 0px 1px 1px 0px #0000001F',
      }}
    >
      <Box
        p={upLg ? '10px 80px 23px' : '10px 20px 20px'}
        px={upLg ? '80px' : downMd ? '20px' : '30px'}
        minHeight={!downSm ? '90px' : '0px'}
      >
        <StyledTextButton
          onClick={handleBack}
          aria-label="back button"
          disableRipple
          variant='text'
          sx={{ height: '26px', margin: 0 }}
        >
          <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
            <ChevronLeftIcon />{' '}
            <Typography fontSize={16} fontWeight={500}>
              {t('common.back')}
            </Typography>
          </Box>
        </StyledTextButton>

        <Box
          display="flex"
          flexDirection={downMd ? 'column' : 'row'}
          gap={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography fontSize={20} mt="14px" fontFamily="WFVisualSans" sx={{ overflowWrap: 'break-word' }}>
            <Typography component="span" fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
              {downMd ? truncateText(MAX_DESCRIPTION_CHARACTERS, data?.description) : data?.description}
            </Typography>{' '}
            <Typography component="span" fontSize={20} fontWeight={400} fontFamily="WFVisualSans">
              for
            </Typography>{' '}
            <Typography component="span" fontSize={20} fontWeight={500} fontFamily="WFVisualSans">
              ${formatCurrency(data?.total_amount)}
            </Typography>
          </Typography>

          {
            <Box aria-label="action-slots-container" display="flex" gap="12px">
              {/* SLOT_1 */}
              {actionRequired ? (
                <StyledSecondaryChip
                  color="secondary"
                  variant="filled"
                  label={t('dashboard.invoiceDetails.incompleteDetails')}
                />
              ) : (
                !data?.is_claimed && (
                  <StyledChip color="info" variant="outlined" label={t('dashboard.invoiceDetails.readyToClaim')} />
                )
              )}

              {/* SLOT_2 */}
              {data?.is_claimed ? (
                <StyledChip
                  variant="filled"
                  color="primary"
                  isDark
                  label={t('dashboard.invoiceDetails.claimed')}
                  icon={<CheckIcon />}
                />
              ) : (
                actionRequired && (
                  <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notClaimed')} />
                )
              )}

              {/* SLOT_3 */}
              {data?.is_paid ? (
                <StyledChip
                  isDark
                  color="primary"
                  variant="filled"
                  label={t('dashboard.invoiceDetails.paid')}
                  icon={<CheckIcon />}
                />
              ) : !data?.due_date ? (
                <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
              ) : downSm ? (
                getDueDaysLeft(data?.due_date) === 0 ? (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.dueToday')}
                    color="error"
                    variant="outlined"
                  />
                ) : getDueDaysLeft(data?.due_date) > 0 && getDueDaysLeft(data?.due_date) <= 10 ? (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.dueSoon')}
                    color="error"
                    variant="outlined"
                  />
                ) : getDueDaysLeft(data?.due_date) > 10 ? (
                  <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
                ) : (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.overdue')}
                    color="error"
                    variant="filled"
                  />
                )
              ) : getDueDaysLeft(data?.due_date) === 0 ? (
                <StyledErrorDueDateChip
                  label={t('dashboard.invoiceDetails.paymentDueToday')}
                  color="error"
                  variant="outlined"
                />
              ) : getDueDaysLeft(data?.due_date) > 0 && getDueDaysLeft(data?.due_date) <= 10 ? (
                <StyledErrorDueDateChip
                  label={t('common.dueIn', {
                    days: `${getDueDaysLeft(data?.due_date)} ${
                      Math.abs(getDueDaysLeft(data?.due_date)) > 1
                        ? `${t('common.days')}`
                        : `${t('common.days').slice(0, -1)}`
                    }`,
                  })}
                  color="error"
                  variant="outlined"
                />
              ) : getDueDaysLeft(data?.due_date) > 10 ? (
                <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
              ) : (
                <StyledErrorDueDateChip
                  label={`${t('common.overdue', {
                    days: Math.abs(getDueDaysLeft(data?.due_date)),
                  })} ${
                    Math.abs(getDueDaysLeft(data?.due_date)) > 1 ? t('common.days') : t('common.days').slice(0, -1)
                  }`}
                  color="error"
                  variant="filled"
                />
              )}
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default memo(DetailsHeader);
