import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import BottomActionBar from '@pages/Tasks/components/BottomActionBar';
import ClaimView from '@pages/Tasks/components/ClaimView';
import PayView from '@pages/Tasks/components/PayView';
import SortSpeedDial from '@pages/Tasks/components/SortSpeedDial';
import ToggleBar from '@pages/Tasks/components/ToggleBar';
import { useTasksContext } from '@pages/Tasks/context';
import { DocumentView, TaskView } from '@pages/Tasks/enums';

import { Box, LinearProgress } from '@mui/material';

const Tasks: FC = () => {
  const { t } = useTranslation();
  const { downMd } = useBreakpoints();

  const {
    view,
    handleChangeView,
    unpaid,
    unclaimed,
    isLoading,
    claimSelectedIds,
    paySelectedIds,
    handleToggleDocumentView,
    handleRemoveFromList,
    handleUnselectAll,
    totalSelectedCost,
    documentView,
    showSpeedDial,
    handleSortByDate,
    handleSortByName,
    handleSortDefault,
  } = useTasksContext();

  if (isLoading) {
    return (
      <Box width="100%">
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.tasks')}</title>
      </Helmet>
      <ToggleBar view={view} onChange={handleChangeView} unpaid={unpaid} unclaimed={unclaimed} />
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        p={{
          xs: '40px 16px 12px',
          sm: '40px 20px 12px',
          md: '40px 30px 12px',
          lg: '40px 80px 12px',
        }}
      >
        {view === TaskView.PAY && <PayView amount={unpaid} />}
        {view === TaskView.CLAIM && <ClaimView amount={unclaimed} />}
      </Box>
      {claimSelectedIds.length > 0 && view === TaskView.CLAIM && documentView === DocumentView.DETAILS && (
        <BottomActionBar
          ids={claimSelectedIds}
          handleMark={handleToggleDocumentView}
          handleRemove={handleRemoveFromList}
          handleUnselect={handleUnselectAll}
          totalCost={totalSelectedCost}
          view={view}
        />
      )}
      {paySelectedIds.length > 0 && view === TaskView.PAY && documentView === DocumentView.DETAILS && (
        <BottomActionBar
          ids={paySelectedIds}
          handleMark={handleToggleDocumentView}
          handleRemove={handleRemoveFromList}
          handleUnselect={handleUnselectAll}
          totalCost={totalSelectedCost}
          view={view}
        />
      )}
      {documentView === DocumentView.DETAILS && downMd && showSpeedDial && (
        <SortSpeedDial sortByDate={handleSortByDate} sortByName={handleSortByName} sortDefault={handleSortDefault} />
      )}
    </>
  );
};

export default memo(Tasks);
